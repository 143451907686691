import { Component, ChangeDetectionStrategy, Inject, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { Product } from '@box-types';

@Component({
  selector: 'unavailable-items-dialog',
  templateUrl: './unavailable-items-dialog.component.html',
  styleUrls: ['./unavailable-items-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnavailableItemsDialogComponent extends BoxDialogWrapperComponent {
  public readonly products: Product[];
  public readonly title: string;
  constructor(
    public override renderer: Renderer2,
    private dialogRef: MatDialogRef<UnavailableItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { title: string; products: Product[] }
  ) {
    super(renderer);
    this.products = this.data.products;
    this.title = this.data.title;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
