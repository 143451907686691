import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  OnChanges,
  SimpleChanges,
  OnInit
} from '@angular/core';
import { groupByBatch } from '@box/utils';

const DEFAULT_BATCH_SIZE = 12; // Least Common Multiple of 1,2,3,4 (the columns of items we may show)
const DEFAULT_INTERSECTION_THRESHOLD_STEPS = 10; // required for Safari

@Component({
  selector: 'progressive-rendering-trigger',
  templateUrl: './progressive-rendering-trigger.component.html',
  styleUrls: ['./progressive-rendering-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressiveRenderingTriggerComponent<T> implements OnInit, OnChanges {
  @Input() public items: T[];
  @Input() public batchSize = DEFAULT_BATCH_SIZE;
  @Output() private triggerEvent = new EventEmitter<T[]>();
  public thresholds: number[];

  private itemGroupIndexes: number[];
  private visibleGroupIndex: number;

  @HostBinding('class') public hostClass = 'progressive-rendering-trigger';
  @HostBinding('class.minimized') public hasReachedEnd: boolean;

  ngOnInit(): void {
    this.thresholds = this.generateThresholds(DEFAULT_INTERSECTION_THRESHOLD_STEPS);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.items = changes.items.currentValue as T[];
      this.initializeData();
    }
  }

  private initializeData(): void {
    this.itemGroupIndexes = [];
    this.visibleGroupIndex = 0;
    this.hasReachedEnd = false;
    this.itemGroupIndexes = this.generateIndexSteps();

    this.onTriggerEnterView();
  }

  private generateIndexSteps(): number[] {
    const itemIndexes = Array.from(Array(this.items.length).keys());
    return groupByBatch(itemIndexes, this.batchSize).map((group) => group[group.length - 1]);
  }

  public onTriggerEnterView(): void {
    this.hasReachedEnd = this.hasEndBeenReached();
    if (this.hasReachedEnd) return;
    this.triggerEvent.emit(this.getVisibleItems());
    this.visibleGroupIndex++;
  }

  private hasEndBeenReached(): boolean {
    return this.visibleGroupIndex > this.itemGroupIndexes.length - 1;
  }

  private getVisibleItems(): T[] {
    if (!this.itemGroupIndexes?.length || !this.items?.length) return [];
    if (this.hasEndBeenReached()) return this.items;
    const visibleItemIndex = this.itemGroupIndexes[this.visibleGroupIndex];
    const visibleItemsLength = visibleItemIndex + 1;
    return this.items.slice(0, visibleItemsLength);
  }

  public generateThresholds(thresholdSteps?: number): number[] {
    const thresholds: number[] = [0];
    if (!thresholdSteps || thresholdSteps <= 0) return thresholds;
    for (let i = 1.0; i <= thresholdSteps; i++) {
      const ratio = i / thresholdSteps;
      thresholds.push(ratio);
    }
    return thresholds;
  }
}
