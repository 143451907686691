import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PromoCoupon } from '@box-types';

@Component({
  selector: 'promo-coupon',
  templateUrl: './promo-coupon.component.html',
  styleUrls: ['./promo-coupon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromoCouponComponent implements OnChanges {
  @Input() public promoCoupon: PromoCoupon;

  public price: string;
  public code: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.promoCoupon) {
      this.promoCoupon = changes.promoCoupon.currentValue as PromoCoupon;
      this.price = this.promoCoupon.text;
      this.code = this.promoCoupon.code;
    }
  }
}
