import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { DialogService, translate } from '@box-core/services';
import {
  displayPointsTag,
  getOfferBaseEnvFee,
  getOfferBestPromo,
  getOfferMaxQuantityPerOrderText,
  getOfferMinOrderItemsPriceText
} from '@box/utils';
import { OfferWizardDFYDialogComponent } from '../offer-wizard-dfy-dialog/offer-wizard-dfy-dialog.component';
import { PromoVisualOptions, OfferInstance, Offer, ProductPromo } from '@box-types';
import { currencyCode } from '@box-core/services/currency.service';

@Component({
  selector: 'offer-wizard-details',
  templateUrl: './offer-wizard-details.component.html',
  styleUrls: ['./offer-wizard-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferWizardDetailsComponent implements OnChanges {
  @Input() public showPrice: boolean;
  @Input() public offer: Offer;
  @Input() public offerInstance: OfferInstance;

  @Output() private offerAdd = new EventEmitter();
  @Output() private offerRemove = new EventEmitter();

  public isDFY: boolean;
  public offerName: string;
  public offerDescription: string;
  public minCartPriceText: string;
  public maxQuantityText: string;
  public offerPreOfferPrice: number;
  public offerBaseEnvFee: number;
  public offerInstancePrice: number;
  public offerInstanceQuantity: number;
  public envFee: number;
  public badgeOptions: PromoVisualOptions;
  public stepToUomDescription: string;
  public productPromo: ProductPromo;
  public displayPointsTag: boolean;

  constructor(private dialogService: DialogService) {}

  @HostBinding('class') public hostClass = 'offer-wizard-details';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showPrice) this.showPrice = changes.showPrice.currentValue as boolean;

    if (changes.offer) {
      this.offer = changes.offer.currentValue as Offer;
      this.productPromo = getOfferBestPromo(this.offer);
      this.isDFY = this.offer.isDFY;
      this.offerName = this.offer.name;
      this.offerDescription = this.offer.description;
      this.minCartPriceText = getOfferMinOrderItemsPriceText(this.offer, translate, currencyCode);
      this.maxQuantityText = getOfferMaxQuantityPerOrderText(this.offer, translate);
      this.offerPreOfferPrice = this.offerInstance.preOfferPrice;
      this.offerBaseEnvFee = getOfferBaseEnvFee(this.offer);
      this.badgeOptions = this.offer.badgeOptions;
      this.displayPointsTag = this.setDisplayPointsTag();
      this.stepToUomDescription = this.offer.stepToUomDescription;
    }

    if (changes.offerInstance) {
      this.offerInstance = changes.offerInstance.currentValue as OfferInstance;
      /*
      If the offer is not yet completed, then were will be no custom price. Therefore
      we do show the basePrice in case of a fixedPrice Offer
      */
      this.offerInstancePrice = this.offerInstance.price || this.offerInstance.basePrice;
      this.offerInstanceQuantity = this.offerInstance.quantity;
    }
  }

  public openDFYDialog(): void {
    const dialogConfig = { panelClass: 'box-dialog-small' };
    this.dialogService.openDialog(OfferWizardDFYDialogComponent, dialogConfig);
  }

  public openDFYWarning(): void {
    this.dialogService.openInfoDialog({
      messages: ['you_can_add_only_one_dfy_offer_per_order']
    });
  }

  public onOfferAdd(): void {
    if (this.isDFY) return this.openDFYWarning();
    this.offerAdd.emit();
  }

  public onOfferRemove(): void {
    this.offerRemove.emit();
  }

  private setDisplayPointsTag(): boolean {
    const { points, multiplier } = this.productPromo ?? {};
    return displayPointsTag(points, multiplier);
  }
}
