import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { PromoVisualOptions } from '@box-types';

@Component({
  selector: 'promo-badge',
  templateUrl: './promo-badge.component.html',
  styleUrls: ['./promo-badge.component.scss']
})
export class PromoBadgeComponent implements OnInit {
  @Input() public options: PromoVisualOptions;

  public text: string;
  public image: string;
  public textBadgeStyles: unknown;

  @HostBinding('class') public hostClass = 'promo-badge';

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.setStateContent(this.options);
  }

  private setStateContent(options: PromoVisualOptions): void {
    this.text = options.text;
    this.image = options.image;
    this.textBadgeStyles = {
      color: options.colors?.foreground,
      backgroundColor: options.colors?.background
    };
    const elementStyle = (this.element.nativeElement as HTMLElement)?.style;
    if (!elementStyle || !options?.colors) return;
    const { background, sideTag } = options?.colors;
    elementStyle.setProperty('--side-tag-color', sideTag ?? background ?? '');
  }
}
