import { CurrencyCode } from '@box-types';

function currencyFormat(cents: number, options?: CurrencyFormatOptions): string {
  if (isNaN(cents)) return;
  const formatOptions: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: options?.currencyCode ?? 'EUR',
    minimumFractionDigits: options?.minimumFractionDigits ?? 0,
    maximumFractionDigits: options?.maximumFractionDigits ?? 2
  };
  const locale = options?.locale ?? 'el-GR';
  const formattedCurrency = new Intl.NumberFormat(locale, formatOptions).format(cents / 100);
  const symbolSpace = options?.symbolSpace ?? true;
  if (symbolSpace) return formattedCurrency;
  return formattedCurrency.replace(/\xa0/g, '');
}

type CurrencyFormatOptions = {
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  symbolSpace?: boolean;
  locale?: string;
  currencyCode: CurrencyCode;
};

export { CurrencyFormatOptions, currencyFormat };
