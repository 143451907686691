import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  HostBinding,
  ChangeDetectorRef
} from '@angular/core';
import { Order, GAItemRatingConfig } from '@box-types';
import { OrderStatusRatingState } from './order-status-rating.types';
import { AnalyticsService, DialogService } from '@box-core/services';
import { ReviewDialogComponent } from '@box-rating-widget/components';
import {
  ReviewDialogData,
  ReviewDialogResponse
} from '@box-rating-widget/components/review-dialog/review-dialog.types';
import { MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'order-status-rating',
  templateUrl: './order-status-rating.component.html',
  styleUrls: ['./order-status-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderStatusRatingComponent implements OnChanges {
  @Input() public order: Order;

  public state: OrderStatusRatingState;
  public title: string;
  public rating: number;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private analyticsService: AnalyticsService
  ) {}

  @HostBinding('class') public hostClass = 'order-status-rating';

  ngOnChanges(changes: SimpleChanges): void {
    this.order = changes.order.currentValue as Order;
    this.rating = this.order.rating ?? 0;
    this.state = this.generateOrderStatusRatingState(this.order);
    this.title = this.generateOrderStatusRatingTitle(this.state);
  }

  public onChangeRating(rating: number): void {
    this.triggerAnalyticsRatingEvent(rating);
    const dialogConfig: MatDialogConfig<ReviewDialogData> = {
      panelClass: 'box-dialog',
      disableClose: true,
      data: { rating, order: this.order, source: 'order_status' }
    };
    this.dialogService
      .openDialog<ReviewDialogComponent, ReviewDialogResponse>(ReviewDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((response) => {
        if (!response?.review) return;
        this.rating = response.review.rating;
        this.state = 'RATED';
        this.title = this.generateOrderStatusRatingTitle(this.state);
        this.changeDetectorRef.detectChanges();
      });
  }

  private generateOrderStatusRatingTitle(state: OrderStatusRatingState): string {
    if (state === 'RATED') return 'thank_you';
    return 'tell_us_your_opinion';
  }

  private generateOrderStatusRatingState(order: Order): OrderStatusRatingState {
    if (!order.rating) return 'UNRATED';
    return 'RATED';
  }

  private triggerAnalyticsRatingEvent(rating: number): void {
    const gaConfig = {
      rating: String(rating),
      source: 'order_status'
    } as GAItemRatingConfig;
    this.analyticsService.addGACustomEvent('item_rating', gaConfig);
  }
}
