import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { Coupon } from '@box-types';
import { finalize } from 'rxjs';
import { RewardsUnavailableCouponsDialogService } from './rewards-unavailable-coupons-dialog.service';

@Component({
  selector: 'rewards-unavailable-coupons-dialog',
  templateUrl: './rewards-unavailable-coupons-dialog.component.html',
  styleUrls: ['./rewards-unavailable-coupons-dialog.component.scss'],
  providers: [RewardsUnavailableCouponsDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RewardsUnavailableCouponsDialogComponent extends BoxDialogWrapperComponent implements OnInit {
  public loading: boolean;
  public coupons: Coupon[];

  constructor(
    public override renderer: Renderer2,
    private dialogRef: MatDialogRef<RewardsUnavailableCouponsDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private rewardsUnavailableCouponsDialogService: RewardsUnavailableCouponsDialogService
  ) {
    super(renderer);
  }

  @HostBinding('class') public hostClass = 'rewards-unavailable-coupons-dialog';

  ngOnInit(): void {
    this.loading = true;
    this.changeDetectorRef.detectChanges();
    this.rewardsUnavailableCouponsDialogService
      .fetchCoupons()
      .pipe(
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe((coupons) => (this.coupons = coupons));
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
