import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Product, OfferInstance, PromoVisualOptions } from '@box-types';
import { getProductInstancePrice, getProductTotalEnvFee } from '@box/utils';

@Component({
  selector: 'offer-wizard-product',
  templateUrl: './offer-wizard-product.component.html',
  styleUrls: ['./offer-wizard-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferWizardProductComponent implements OnChanges {
  @Input() public product: Product;
  @Input() public offerInstance: OfferInstance;
  @Input() public isGift = false;
  @Output() private productMYO = new EventEmitter();

  public name: string;
  public description: string;
  public productPrice: number;
  public badgeOptions: PromoVisualOptions;
  public totalEnvFee: number;
  public showPrice: boolean;

  @HostBinding('class') public hostClass = 'offer-wizard-product';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.product) {
      this.product = changes.product.currentValue as Product;
      const productInstance = this.product.cartInstances[0];
      this.totalEnvFee = getProductTotalEnvFee(productInstance);
      this.badgeOptions = this.product.badgeOptions;
      this.productPrice = getProductInstancePrice(
        productInstance,
        this.offerInstance.percentageDiscount,
        this.offerInstance.extraIngredientsHavePercentageDiscount
      );
      this.name = this.product.name;
      this.description =
        this.product.checked && productInstance.description ? productInstance.description : this.product.info?.el;
    }

    if (changes.offerInstance) this.offerInstance = changes.offerInstance.currentValue as OfferInstance;
    if (changes.isGift) this.isGift = changes.isGift.currentValue as boolean;

    const isOfferFixedPrice = this.offerInstance?.offerType === 'fixedPrice';
    this.showPrice = !this.isGift && this.productPrice > 0 && !isOfferFixedPrice;
  }

  public onProductMYO(): void {
    this.productMYO.emit();
  }
}
