import { Component, OnInit, Inject, Renderer2, ChangeDetectionStrategy } from '@angular/core';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { generateImageSrc } from '@box/utils';
import { WhatsNewConfiguration } from '@box-types';

@Component({
  selector: 'whats-new-dialog',
  templateUrl: './whats-new-dialog.component.html',
  styleUrls: ['./whats-new-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhatsNewDialogComponent extends BoxDialogWrapperComponent implements OnInit {
  public title: string;
  public imageUrl: string;
  public description: string;
  public buttonColor: string;
  constructor(
    public override renderer: Renderer2,
    private dialogRef: MatDialogRef<WhatsNewDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: WhatsNewConfiguration
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.title = this.data.title;
    this.imageUrl = generateImageSrc(this.data.image);
    this.description = this.data.description;
    this.buttonColor = this.imageUrl ? this.data.buttonColor : null;
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
