import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { AnalyticsService, PurchaseEventService } from '@box-core/services';
import { ChainTile, GAPromotionConfig } from '@box-types';
import { Mousewheel, Navigation } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';
import { Router } from '@angular/router';
import { getCollectionHomeChainsImpressionGAConfig, HomeSection, generateImageSrc } from '@box/utils';

@Component({
  selector: 'home-brands',
  templateUrl: './home-brands.component.html',
  styleUrls: ['./home-brands.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeBrandsComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('nextBtn', { static: true }) private nextBtn: MatButton;
  @ViewChild('prevBtn', { static: true }) private prevBtn: MatButton;
  @ViewChild('navButtonContainer', { static: true }) private navButtonContainer: ElementRef<HTMLDivElement>;

  @Input() public homeSection: HomeSection;

  public chainsTiles: ChainTile[];
  public title: string;
  public swiperOptions: SwiperOptions;
  public readonly DEFAULT_INTERSECTION_THRESHOLDS = 0.8;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private analyticsService: AnalyticsService,
    private purchaseEventService: PurchaseEventService
  ) {}

  @HostBinding('class') public hostClass = 'home-brands';

  ngOnInit(): void {
    this.setSwiperOptions();
  }

  ngAfterViewInit(): void {
    const nextEl: HTMLElement = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl: HTMLElement = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const buttonContainer: HTMLDivElement = this.navButtonContainer.nativeElement;
    const nextButtonIsDisabled = nextEl.classList.contains('swiper-button-disabled');
    const prevButtonIsDisabled = prevEl.classList.contains('swiper-button-disabled');
    if (nextButtonIsDisabled && prevButtonIsDisabled) {
      this.renderer.addClass(buttonContainer, 'hidden');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.homeSection = changes.homeSection.currentValue as HomeSection;
    this.setSectionContent(this.homeSection);
  }

  public onChainSelection(chainTile: ChainTile): void {
    if (!chainTile.shop) return;
    const vanityUrl = chainTile.shop.vanity_url;
    const locationKey = chainTile.shop.locationKey;
    if (!vanityUrl || !locationKey) return;
    void this.router.navigate(['/delivery', locationKey, vanityUrl]);
    this.triggerAnalyticsSelectPromoEvent(chainTile);
  }

  private setSectionContent(homeSection: HomeSection): void {
    this.title = homeSection.title;
    this.chainsTiles = homeSection.chainTiles;
  }

  private setSwiperOptions(): void {
    const nextEl: HTMLElement = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl: HTMLElement = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    this.swiperOptions = {
      modules: [Navigation, Mousewheel],
      navigation: { nextEl, prevEl },
      mousewheel: { forceToAxis: true, releaseOnEdges: false },
      slidesPerView: 'auto',
      grabCursor: true,
      spaceBetween: 8
    };
  }

  public getChainTileImageUrl(chainTile: ChainTile): string {
    const imageUrl = chainTile.chain.image ?? chainTile.shop.logo;
    return generateImageSrc(imageUrl);
  }

  public onChainEnteredViewport(chainTile: ChainTile): void {
    this.triggerAnalyticsViewPromoEvent(chainTile);
  }

  private triggerAnalyticsViewPromoEvent(chainTile: ChainTile): void {
    const gaConfig = getCollectionHomeChainsImpressionGAConfig(this.homeSection, this.chainsTiles, chainTile);
    this.analyticsService.addGAEcommerceEvent('view_promotion', gaConfig);
  }

  private triggerAnalyticsSelectPromoEvent(chainTile: ChainTile): void {
    const gaConfig = getCollectionHomeChainsImpressionGAConfig(this.homeSection, this.chainsTiles, chainTile);
    this.analyticsService.addGAEcommerceEvent('select_promotion', gaConfig);
    this.updatePurchaseEvent(chainTile, gaConfig);
  }

  private updatePurchaseEvent(chainTile: ChainTile, gaConfig: GAPromotionConfig) {
    if (!gaConfig?.items?.length) return;
    const shopIndex = gaConfig['items'][0].index;
    const purchaseEvent = {
      creativeName: '',
      index: shopIndex,
      itemListName: this.homeSection.slug,
      creativeSlot: 'discover_home',
      promotionName: this.homeSection.title
    };
    this.purchaseEventService.updatePurchaseEvent(chainTile.shop._id, purchaseEvent);
  }
}
