<section class="section">
  <div class="container">
    <div class="cookies-wrapper">
      <div class="cookies-content">
        <h3>{{ 'cookies_management' | translate }}</h3>
        <div class="cookies-content-scrollable">
          <div class="cookies-content-wrapper">
            <p [innerHTML]="'cookies_part1' | translate | safeHtml"></p>
            <ul class="cookies-content-list">
              <li>
                <span class="cookies-content-square">&#9632;</span>
                <span [innerHTML]="'cookies_part2' | translate | safeHtml"></span>
              </li>
              <li>
                <span class="cookies-content-square">&#9632;</span>
                <span [innerHTML]="'cookies_part3' | translate | safeHtml"></span>
              </li>
              <li>
                <span class="cookies-content-square">&#9632;</span>
                <span [innerHTML]="'cookies_part4' | translate | safeHtml"></span>
              </li>
            </ul>
            <p [innerHTML]="'cookies_part5' | translate | safeHtml"></p>
          </div>
        </div>
      </div>
      <div class="cookies-actions">
        <button box-button [theme]="'primary'" class="cookies-button" (click)="accept()">
          {{ 'accept_cookies' | translate }}
        </button>
        <button box-button [theme]="'primary'" class="cookies-button" (click)="decline()">
          {{ 'reject_cookies' | translate }}
        </button>
        <button class="cookies-actions-more" box-button (click)="onCookiesClick()">
          {{ 'informations_and_settings' | translate }}
        </button>
      </div>
    </div>
  </div>
</section>
