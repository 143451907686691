import { AfterViewInit, Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { SwiperOptions } from 'swiper/types';
import Swiper from 'swiper';

@Directive({ selector: '[swiper]' })
export class SwiperDirective implements AfterViewInit {
  @Input('swiper') public swiperOptions?: SwiperOptions;

  private swiper: Swiper;

  constructor(private element: ElementRef<HTMLElement>) {}

  @HostBinding('class') public hostClass = 'swiper';

  ngAfterViewInit() {
    this.swiper = new Swiper(this.element.nativeElement, this.swiperOptions);
  }

  public getSwiper(): Swiper {
    return this.swiper;
  }
}
