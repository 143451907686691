import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Directive({
  selector: '[visibilityObserver]'
})
export class VisibilityObserverDirective implements AfterViewInit {
  @Input() intersectionRoot: HTMLElement = null;
  @Input() intersectionRootMargin = '0px 0px 0px 0px';
  /**
   * we are notified when the element intersects any of the thresholds specified
   * @type {number | number[]}
   */
  @Input() intersectionThresholds: number | number[] = [0];
  /**
   * When this input is true means that we need to stop observing the specified target element after the first time.
   * @type {boolean}
   */
  @Input() triggerOnce = false;

  @Output() leavingViewport: EventEmitter<IntersectionObserverEntry> = new EventEmitter<IntersectionObserverEntry>();
  @Output() enteredViewport: EventEmitter<IntersectionObserverEntry> = new EventEmitter<IntersectionObserverEntry>();

  private isElementPartiallyVisible: boolean;

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    this.initiateObserver();
  }

  private initiateObserver(): void {
    const options = this.generateOptions();
    const observer = new IntersectionObserver((records) => {
      if (!records.length) return;
      const record = records[0];

      if (record.isIntersecting) {
        this.isElementPartiallyVisible = true;
        if (this.triggerOnce) observer.unobserve(record.target);
        return this.enteredViewport.emit();
      }
      if (record.isIntersecting === false) {
        if (this.isElementPartiallyVisible) this.leavingViewport.emit();
        this.isElementPartiallyVisible = false;
      }
    }, options);

    observer.observe(this.elementRef.nativeElement);
  }

  private generateOptions(): IntersectionObserverInit {
    return {
      /* In Safari the "root" parameter can not be a Document */
      root: this.intersectionRoot, // defaults to the browser viewport, otherwise works best if it is an element with a scrollbar
      rootMargin: this.intersectionRootMargin, // This set of values serves to grow or shrink each side of the root element's bounding box before computing intersections. Defaults to all zeros.
      threshold: this.intersectionThresholds // A threshold of 1.0 means that when 100% of the target is visible within the element specified by the root option, the callback is invoked. 0 is the most common option
    };
  }
}
