import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Cuisine } from '@box-types';
import { DeliveryCuisineHeroService } from './delivery-cuisine-hero.service';

@Component({
  selector: 'delivery-cuisine-hero',
  templateUrl: './delivery-cuisine-hero.component.html',
  styleUrls: ['./delivery-cuisine-hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DeliveryCuisineHeroService]
})
export class DeliveryCuisineHeroComponent implements OnChanges {
  @Input() public cuisine: Cuisine;

  public imageAlt: string;
  public imageURL: string;
  public backgroundColor: string;

  constructor(private deliveryCuisineHeroService: DeliveryCuisineHeroService) {}

  @HostBinding('class') public hostClass = 'delivery-cuisine-hero';

  ngOnChanges(changes: SimpleChanges): void {
    this.cuisine = changes.cuisine.currentValue as Cuisine;
    this.backgroundColor = this.deliveryCuisineHeroService.getCuisineColor(this.cuisine);
    this.imageAlt = `${this.cuisine?.name} Delivery`;
    this.imageURL = this.deliveryCuisineHeroService.getCuisineImage(this.cuisine);
  }
}
