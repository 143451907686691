<div class="shop-menu-search-wrapper">
  <search-input
    [placeholder]="placeholder"
    (searchInput)="onInput($event)"
    (focusInput)="onFocus()"
    (clearInput)="clearSearch()"
    (keyboardEscape)="onEscape()"
  ></search-input>
  <ng-container *ngIf="resultsOpen">
    <div class="shop-menu-search-overlay" (click)="closeResults()"></div>
    <div *ngIf="searchTerm.length > 2" class="shop-menu-search-results" [style.max-height.px]="resultsMaxHeight">
      <component-loader *ngIf="loading; else loadedContent"></component-loader>
      <ng-template #loadedContent>
        <ng-container *ngIf="hasResults; else emptyState">
          <div *ngIf="categoryView" class="shop-menu-search-results-market-container">
            <ng-container *ngFor="let offer of offers; let i = index">
              <market-item
                class="market-item"
                [item]="offer"
                [isOffer]="true"
                (add)="onOfferAdd(offer, 'supermarket')"
                (click)="onOfferClick(i, offer, 'supermarket')"
                (remove)="onOfferRemove(offer, 'supermarket')"
              ></market-item>
            </ng-container>
            <ng-container *ngFor="let product of products; let i = index">
              <market-item
                class="market-item"
                [item]="product"
                (add)="onProductAdd(product, 'supermarket')"
                (click)="onProductClick(i, product, 'supermarket')"
                (remove)="onProductRemove(product, 'supermarket')"
              ></market-item>
            </ng-container>
          </div>
          <ng-container *ngIf="!categoryView">
            <ng-container *ngFor="let offer of offers; let i = index; let last = last">
              <food-offer
                class="hoverable"
                [offer]="offer"
                (add)="onOfferAdd($event, 'regular')"
                (click)="onOfferClick(i, offer, 'regular')"
                (remove)="onOfferRemove(offer, 'regular')"
              ></food-offer>
              <div *ngIf="!last" class="box-divider"></div>
            </ng-container>
            <ng-container *ngFor="let product of products; let i = index; let last = last">
              <food-product
                class="hoverable"
                [product]="product"
                (add)="onProductAdd($event, 'regular')"
                (view)="onProductClick(i, product, 'regular')"
                (remove)="onProductRemove(product, 'regular')"
              ></food-product>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-template #emptyState>
          <div class="shop-menu-search-results-empty-state">
            <mat-icon class="shop-menu-search-results-empty-state-icon" fontSet="material-icons-round">search</mat-icon>
            <span class="shop-menu-search-results-empty-state-text ods-typography-body-s-regular">{{
              'no_results_found_for' | translate: { _SEARCH_TERM: '“' + searchTerm + '”' }
            }}</span>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </ng-container>
</div>
