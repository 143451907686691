import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { BOXNavOptions, Review } from '@box-types';
import { canShowShopRatings } from '@box/utils';
import { finalize, Subscription } from 'rxjs';
import { ShopPageDetailsDialogService } from './shop-page-details-dialog.service';
import { ShopPageDetailsDialogData, ShopPageDetailsDialogState } from './shop-page-details-dialog.types';

@Component({
  selector: 'shop-page-details-dialog',
  templateUrl: './shop-page-details-dialog.component.html',
  styleUrls: ['./shop-page-details-dialog.component.scss'],
  providers: [ShopPageDetailsDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopPageDetailsDialogComponent extends BoxDialogWrapperComponent implements OnInit, OnDestroy {
  public state: ShopPageDetailsDialogState;
  public navOptions: BOXNavOptions = { separators: false, elevated: false };
  public loading: boolean;

  public reviewsTabTitle: string;
  public reviews: Review[];

  private stateSubscription: Subscription;
  private reviewsSubscription: Subscription;

  constructor(
    public override renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<ShopPageDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ShopPageDetailsDialogData,
    private shopDetailsDialogService: ShopPageDetailsDialogService
  ) {
    super(renderer);
  }

  @HostBinding('class') public hostClass = 'shop-page-details-dialog';

  ngOnInit(): void {
    if (this.data.state) this.shopDetailsDialogService.setState(this.data.state);
    this.setStateSubscription();
    this.setReviewsSubscription();
    this.fetchReviews();
  }

  ngOnDestroy(): void {
    this.stateSubscription?.unsubscribe();
    this.reviewsSubscription?.unsubscribe();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public onStateChange(state: ShopPageDetailsDialogState): void {
    if (this.state === state) return;
    this.shopDetailsDialogService.setState(state);
  }

  private setStateSubscription(): void {
    this.stateSubscription = this.shopDetailsDialogService.state$.subscribe((state) => (this.state = state));
  }

  private setReviewsSubscription(): void {
    this.stateSubscription = this.shopDetailsDialogService.reviews$.subscribe((reviews) => {
      const showReviews = canShowShopRatings(reviews.length);
      this.reviews = showReviews ? reviews : [];
      this.reviewsTabTitle = this.shopDetailsDialogService.generateReviewsTabTitle(reviews);
      this.changeDetectorRef.detectChanges();
    });
  }

  private fetchReviews(): void {
    this.loading = true;
    this.changeDetectorRef.detectChanges();
    this.shopDetailsDialogService
      .fetchReviews()
      .pipe(
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe((reviews) => this.shopDetailsDialogService.setReviews(reviews));
  }
}
