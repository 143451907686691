import { cloneDeep, orderBy } from 'lodash-es';
import {
  createProductInstance,
  getAllExceptCheapestProduct,
  getProductFromInstance,
  getProductInstancePrice,
  getProductTotalEnvFee,
  remainingRequiredChoices
} from '../products';
import { Product, ProductInstance, Offer, OfferInstance, OfferInstanceType, OfferGroup } from '@box-types';
import { applyPercentageDiscount, toInteger } from '../core';

export {
  getOffersSelectedProducts,
  getOfferInstanceProductInstancesWithoutCheapest,
  getOfferInstanceSelectedChargedProducts,
  getOfferInstancesSelectedProductInstances,
  getOfferInstanceFreeProductsEnvFees,
  getOfferInstanceProductsPrice,
  getOfferInstanceTotalEnvFee,
  getInitialOfferInstancePrice,
  getOfferInstanceProductsMinimumPrice,
  getOfferFromInstance,
  getOfferInstanceType,
  generateOfferInstanceGroups,
  offerInstanceHasProducts,
  getOfferGroupSelectedProductIngredientsPrice,
  getOfferInstancePrice,
  createOfferInstance,
  getOfferProductNamesWithRemainingChoices,
  getOfferInstanceFreeGroupIndex
};

// gets all offers selected products including gift ones
function getOffersSelectedProducts(offers: Offer[]): Product[] {
  return offers
    .filter((offer) => offer.cartInstances?.length)
    .flatMap((offer) => offer.cartInstances)
    .filter((instance) => instance.groups?.length)
    .flatMap((instance) => {
      const { quantity, groups } = instance;
      return groups.map((group) => ({ ...group.selectedProduct, quantity }));
    });
}

// gets only the offers selected products the user is going to pay
function getOfferInstanceProductInstancesWithoutCheapest(instance: OfferInstance): ProductInstance[] {
  if (!instance?.groups?.length) return undefined;
  const productInstances: ProductInstance[] = instance.groups
    .filter((group) => group.type !== 'gift' && Boolean(group.selectedProduct))
    .flatMap((group) => group.selectedProduct.cartInstances[0]);
  return getAllExceptCheapestProduct(productInstances, instance?.percentageDiscount);
}

function getOfferInstanceSelectedChargedProducts(instance: OfferInstance): ProductInstance[] {
  if (!instance?.groups?.length) return [];
  const nonGiftGroups = instance.groups.filter((g) => g.type !== 'gift');
  const groupSelectedProducts = nonGiftGroups
    .flatMap((group) => group.selectedProduct?.cartInstances[0])
    .filter(Boolean);
  if (instance.offerType !== 'minPriceAsGift') return groupSelectedProducts;
  return getOfferInstanceProductInstancesWithoutCheapest(instance);
}

// gets all offer instances selected products including gift ones
function getOfferInstancesSelectedProductInstances(instance: OfferInstance): ProductInstance[] {
  if (!instance?.groups?.length) return null;
  return instance.groups
    .filter((group) => group.selectedProduct?.cartInstances?.length)
    .flatMap((group) => group.selectedProduct.cartInstances[0]); // an offer cannot have  multiple product.cartInstances
}

function getOfferInstanceFreeProductsEnvFees(instance: OfferInstance): number {
  if (!instance) return 0;
  const chargedSelectedProductsIds = getOfferInstanceSelectedChargedProducts(instance).map(
    (product) => product.instanceId
  );
  const freeProducts = getOfferInstancesSelectedProductInstances(instance).filter(
    (product) => !chargedSelectedProductsIds.includes(product.instanceId)
  );
  if (!freeProducts?.length) return 0;
  return freeProducts.reduce((acc, product) => acc + (getProductTotalEnvFee(product) ?? 0), 0);
}

function getOfferInstanceProductsPrice(instance: OfferInstance): number {
  if (!instance?.groups?.length) return null;
  const freeProductEnvFees = instance.envFeeChargedForOfferGifts ? getOfferInstanceFreeProductsEnvFees(instance) : 0;
  const productsPrice = instance.groups
    .filter((g) => g.type !== 'gift')
    .reduce((acc, group) => {
      const groupSelectedProduct = group.selectedProduct?.cartInstances[0];
      return (
        acc +
        getProductInstancePrice(
          groupSelectedProduct,
          instance.percentageDiscount,
          instance.extraIngredientsHavePercentageDiscount
        )
      );
    }, 0);
  return productsPrice + freeProductEnvFees;
}

function getOfferInstanceTotalEnvFee(offer: OfferInstance): number {
  if (!offer) return 0;
  const offerSelectedProducts = offer.envFeeChargedForOfferGifts
    ? getOfferInstancesSelectedProductInstances(offer)
    : getOfferInstanceSelectedChargedProducts(offer);
  return offerSelectedProducts?.reduce((acc, prod) => acc + getProductTotalEnvFee(prod), 0) * offer.quantity || 0;
}

function getInitialOfferInstancePrice(instance: OfferInstance): number {
  if (!instance?.groups?.length) return null;
  const productInstances = getOfferInstancesSelectedProductInstances(instance);
  if (productInstances.length !== 0) return getOfferInstanceProductsPrice(instance);
  const defaultOfferInstancePrice = instance.groups
    .filter((g) => g.type !== 'gift')
    .reduce((acc, cur) => acc + cur.products[0].price, 0);
  const freeProductEnvFees = instance.envFeeChargedForOfferGifts ? getOfferInstanceFreeProductsEnvFees(instance) : 0;
  // not taking env fee into consideration when calculating offer's percentageDiscount
  const totalEnvFee = getOfferInstanceTotalEnvFee(instance);
  return (
    applyPercentageDiscount(defaultOfferInstancePrice - totalEnvFee, instance.percentageDiscount) +
    totalEnvFee +
    freeProductEnvFees
  );
}

function getOfferInstanceProductsMinimumPrice(instance: OfferInstance): number {
  if (!instance?.groups?.length) return 0;
  const productPrices: number[] = [];
  instance.groups
    .filter((group) => group.type !== 'gift')
    .forEach((group) => {
      const groupSelectedProduct = group.selectedProduct?.cartInstances[0];
      const productFinalPrice = getProductInstancePrice(
        groupSelectedProduct,
        instance.percentageDiscount,
        instance.extraIngredientsHavePercentageDiscount
      );
      productPrices.push(productFinalPrice);
    });
  return Math.min(...productPrices);
}

function getOfferFromInstance(offer: Offer, instance: OfferInstance): Offer {
  const newOffer = cloneDeep(offer);
  delete newOffer.cartInstances;
  delete newOffer.cartQuantity;
  newOffer.quantity = instance.quantity;
  newOffer.groups = cloneDeep(instance.groups);
  newOffer.groups.forEach((group) => {
    if (!group.selectedProduct) return;
    group.selectedProduct._id = group.selectedProduct.productId;
    const offerProduct = getProductFromInstance(group.selectedProduct, group.selectedProduct.cartInstances[0]);
    group.products = [offerProduct];
    delete group.selectedProduct;
  });
  return newOffer;
}

function getOfferInstanceType(offer: Offer): OfferInstanceType {
  if (offer.rule === 'minPriceAsGift') return 'minPriceAsGift';
  if (offer.rule === 'none') {
    if (offer.percentageDiscount > 0) return 'percentageDiscount';
    if (offer.price > 0) return 'fixedPrice';
  }
  return 'normal';
}

function generateOfferInstanceGroups(groups: OfferGroup[]): OfferGroup[] {
  const offerGroups = cloneDeep(groups);

  offerGroups.forEach((group) => {
    group.products.sort((a, b) => a.productIndex - b.productIndex);
    group.products.forEach((product) => {
      product.cartInstances = [];
      product.cartInstances.push(createProductInstance(product));
    });
  });

  return offerGroups;
}

function offerInstanceHasProducts(instance: OfferInstance): boolean {
  if (!instance?.groups?.length) return false;
  return instance.groups.every((group) => group.products?.length && group.selectedProduct);
}

function getOfferGroupSelectedProductIngredientsPrice(group: OfferGroup, isOfferFixed = false): number {
  const selectedProduct = group?.selectedProduct?.cartInstances[0];
  if (!selectedProduct) return 0;
  if (selectedProduct.hasCustomPrice && isOfferFixed) return 0;
  const priceWithIngredients = selectedProduct.price;
  const basePrice = selectedProduct.basePrice;
  const finalPrice = priceWithIngredients - basePrice;
  if (isOfferFixed) return Math.max(0, finalPrice);
  return finalPrice;
}

function getOfferInstancePrice(instance: OfferInstance): number {
  if (!offerInstanceHasProducts(instance)) return 0;
  if (instance.isDFY) return instance.basePrice;

  switch (instance.offerType) {
    case 'minPriceAsGift': {
      const minimumPriceWithDiscount = getOfferInstanceProductsMinimumPrice(instance);
      const initialOfferInstancePrice = getInitialOfferInstancePrice(instance);
      return toInteger(initialOfferInstancePrice - minimumPriceWithDiscount);
    }
    case 'normal':
    case 'percentageDiscount':
      return getInitialOfferInstancePrice(instance);
    case 'fixedPrice': {
      const ingredientsPrice = instance.groups.reduce(
        (acc, group) => acc + getOfferGroupSelectedProductIngredientsPrice(group, true),
        0
      );
      return instance.basePrice + ingredientsPrice;
    }
    default:
      return 0;
  }
}

function createOfferInstance(offer: Offer): OfferInstance {
  const instance: OfferInstance = {
    quantity: offer.quantity || 1,
    percentageDiscount: offer.percentageDiscount,
    extraIngredientsHavePercentageDiscount: offer.extraIngredientsHavePercentageDiscount,
    offerType: getOfferInstanceType(offer),
    isDFY: offer.isDFY,
    groups: generateOfferInstanceGroups(offer.groups),
    preOfferPrice: offer.preOfferPrice,
    basePrice: offer.price, // todo refactor
    description: offer.description,
    volume: offer.volume ?? 0,
    envFeeChargedForOfferGifts: offer.envFeeChargedForOfferGifts
  } as OfferInstance;

  instance.price = getOfferInstancePrice(instance);
  return instance;
}

function getOfferProductNamesWithRemainingChoices(instance: OfferInstance): string[] {
  const selectedProducts = instance.groups.map((group) => group.selectedProduct).filter(Boolean);
  return selectedProducts
    .filter((product) => remainingRequiredChoices(product.cartInstances[0]) > 0)
    .map((product) => product.name);
}

function getOfferInstanceFreeGroupIndex(instance: OfferInstance): number {
  if (instance.offerType !== 'minPriceAsGift') return -1;
  const groupsData = instance.groups.map((group, index) => ({
    index,
    price: group.selectedProduct.cartInstances[0].price,
    type: group.type
  }));
  const sortedGroupsData = orderBy(groupsData, 'price');
  return sortedGroupsData.find((group) => group.type !== 'gift').index;
}
