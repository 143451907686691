import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { CheckoutSuggestionBanner, GAPromotionConfig } from '@box-types';
import { PaginationOptions, SwiperOptions } from 'swiper/types';
import { CheckoutSuggestionBannersService } from './checkout-suggestion-banners.service';
import { Autoplay, Mousewheel, Pagination } from 'swiper/modules';
import { SwiperDirective } from '@box-shared/directives';
import { AnalyticsService, PromoCampaignsService, ShopService } from '@box-core/services';
import { CheckoutService, CheckoutStateService } from '@box-checkout/services';

@Component({
  selector: 'checkout-suggestion-banners',
  templateUrl: './checkout-suggestion-banners.component.html',
  styleUrls: ['./checkout-suggestion-banners.component.scss'],
  providers: [CheckoutSuggestionBannersService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutSuggestionBannersComponent implements OnInit, AfterViewInit {
  @ViewChild(SwiperDirective) private swiperDirective: SwiperDirective;
  @Input() public checkoutSuggestionBanners: CheckoutSuggestionBanner[];

  public alternativeView: boolean;
  public activeIndex: number;
  public swiperOptions: SwiperOptions;
  private readonly AUTOPLAY_DELAY = 2800;
  public readonly DEFAULT_INTERSECTION_THRESHOLDS = 1.0;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private checkoutSuggestionBannersService: CheckoutSuggestionBannersService,
    private analyticsService: AnalyticsService,
    private checkoutService: CheckoutService,
    private checkoutStateService: CheckoutStateService,
    private promoCampaignsService: PromoCampaignsService,
    private shopService: ShopService
  ) {}

  @HostBinding('class') public hostClass = 'checkout-suggestion-banners';

  ngOnInit(): void {
    this.alternativeView = this.checkoutSuggestionBannersService.exceededThreshold(this.checkoutSuggestionBanners);
    this.setSwiperOptions();
  }

  ngAfterViewInit(): void {
    this.setSwiperAutoplayCallbacks();
  }

  public onBannerSelect(banner: CheckoutSuggestionBanner): void {
    this.checkoutSuggestionBannersService
      .openCheckoutSuggestionDialog(banner)
      .afterClosed()
      .subscribe((response) => {
        if (!response?.addToCart) return;
        this.checkoutSuggestionBannersService.addProductsToCart(response.products);
        this.checkoutSuggestionBannersService.addOffersToCart(response.offers);
        this.onBannerAddItemsToCart(banner);
      });
  }

  private onBannerAddItemsToCart(banner: CheckoutSuggestionBanner): void {
    const bannerAlreadyShown = this.checkoutStateService.getPromoCampaignsShowed()?.includes(banner.campaignName);
    if (bannerAlreadyShown) return;
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    const promoCampaign = promoCampaigns.find((campaign) => campaign.name === banner.campaignName);
    if (!promoCampaign) return;
    const promoCampaignBanner = this.promoCampaignsService.campaignToCampaignBanner(promoCampaign);
    this.checkoutService
      .openSynergyDialog(this.shopService.getShop(), [promoCampaignBanner])
      .afterClosed()
      .subscribe(() => this.checkoutService.addPromoCampaignsShowed(banner.campaignName));
  }

  public onBannerClick(banner: CheckoutSuggestionBanner): void {
    this.onBannerSelect(banner);
    this.triggerAnalyticsEvent('select_promotion', banner);
  }

  private getPaginationOptions(): PaginationOptions | boolean {
    if (this.alternativeView) return false;
    return { el: '.swiper-pagination', type: 'bullets', clickable: true };
  }

  private setSwiperOptions(): void {
    this.swiperOptions = {
      modules: [Mousewheel, Pagination, Autoplay],
      pagination: this.getPaginationOptions(),
      mousewheel: { forceToAxis: true, releaseOnEdges: false },
      autoplay: { delay: this.AUTOPLAY_DELAY, disableOnInteraction: false, stopOnLastSlide: true },
      slidesPerView: 'auto',
      grabCursor: true
    };
  }

  private setSwiperAutoplayCallbacks(): void {
    const swiper = this.swiperDirective.getSwiper();
    this.activeIndex = swiper.activeIndex;
    this.changeDetectorRef.detectChanges();

    swiper.on('activeIndexChange', (swiper) => {
      this.activeIndex = swiper.activeIndex;
      this.changeDetectorRef.detectChanges();
    });

    swiper.once('reachEnd', (swiper) => {
      swiper.autoplay.stop();
      setTimeout(() => {
        swiper.slideTo(0);
        this.changeDetectorRef.detectChanges();
      }, this.AUTOPLAY_DELAY);
    });
  }

  public onBannerEnteredViewport(banner: CheckoutSuggestionBanner): void {
    this.triggerAnalyticsEvent('view_promotion', banner);
  }

  private triggerAnalyticsEvent(eventName: string, banner: CheckoutSuggestionBanner): void {
    const gaConfig = {
      creative_name: '',
      creative_slot: 'cart',
      promotion_id: '',
      promotion_name: banner.campaignName
    } as GAPromotionConfig;
    this.analyticsService.addGAEcommerceEvent(eventName, gaConfig);
  }
}
