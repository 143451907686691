<div class="checkout-coupon-code-wrapper box-dialog-wrapper">
  <header class="checkout-coupon-code-header box-dialog-header">
    <box-dialog-header title="coupon_insertion" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main class="box-dialog-content">
    <div class="checkout-coupon-code-body">
      <p class="checkout-coupon-code-instructions">{{ 'enter_your_coupon_code_and_earn' | translate }}</p>
      <mat-form-field class="checkout-coupon-code-field" [floatLabel]="'auto'">
        <input
          #couponInput
          matInput
          name="couponCode"
          class="checkout-coupon-code-input"
          [placeholder]="'type_your_code_here' | translate"
          [formControl]="couponFormControl"
          (input)="onCouponInput()"
          (keydown.enter)="onSubmit()"
        />
        <mat-error *ngIf="couponFormControl?.errors?.invalid">{{
          'coupon_code_invalid_chars_message' | translate: { INVALID_CHARS: couponFormControl?.errors?.invalid.value }
        }}</mat-error>
      </mat-form-field>
    </div>
  </main>
  <footer class="checkout-coupon-code-footer box-dialog-footer">
    <button box-button theme="primary" class="checkout-coupon-code-btn" (click)="onSubmit()" [disabled]="!canSubmit">
      {{ 'apply_' | translate }}
    </button>
  </footer>
</div>
