import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { BOXEmptyStateOptions } from '@box-types';

@Component({
  selector: 'box-empty-state',
  templateUrl: './box-empty-state.component.html',
  styleUrls: ['./box-empty-state.component.scss']
})
export class BoxEmptyStateComponent implements OnInit {
  @Input() public options: BOXEmptyStateOptions;

  public iconURL: string;
  public title: string;
  public description: string;

  @HostBinding('class') public hostClass = 'box-empty-state';

  ngOnInit(): void {
    this.setStateContent(this.options);
  }

  private setStateContent(options: BOXEmptyStateOptions): void {
    const { iconURL, title, description } = options;
    this.iconURL = iconURL;
    this.title = title;
    this.description = description;
  }
}
