/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
/* We disable the above eslint rules due to the nature of the Injector in Angular. */
import { Injector, Type, InjectionToken } from '@angular/core';

export class NotificationInjector<T> implements Injector {
  constructor(
    private _parentInjector: Injector,
    private _additionalTokens: WeakMap<any, T>
  ) {}

  get(token: Type<T> | InjectionToken<T>, notFoundValue?: T): T {
    const value = this._additionalTokens.get(token);
    if (value) return value;
    return this._parentInjector.get<T>(token, notFoundValue);
  }
}
