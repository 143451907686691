import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  HostBinding,
  Input,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { Order } from '@box-types';
import { Mousewheel } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';
import { OrderStatusPreviewsService } from './order-status-previews.service';
import { Subscription } from 'rxjs';
import { getOrderAcceptedStatusText } from '@box/utils';

@Component({
  selector: 'order-status-previews',
  templateUrl: './order-status-previews.component.html',
  styleUrls: ['./order-status-previews.component.scss'],
  providers: [OrderStatusPreviewsService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderStatusPreviewsComponent implements OnInit, OnDestroy {
  @Input() public orders: Order[];

  public readonly swiperOptions: SwiperOptions = {
    modules: [Mousewheel],
    mousewheel: { forceToAxis: true, releaseOnEdges: false },
    slidesPerView: 'auto',
    grabCursor: true,
    spaceBetween: 4
  };

  private ordersSubscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private orderStatusPreviewsService: OrderStatusPreviewsService
  ) {}

  @HostBinding('class') public hostClass = 'order-status-previews';

  ngOnInit(): void {
    this.orderStatusPreviewsService.init(this.orders);
    this.setOrdersSubscription();
  }

  ngOnDestroy(): void {
    this.ordersSubscription?.unsubscribe();
  }

  public trackByStatus(index: number, order: Order): string {
    return getOrderAcceptedStatusText(order, 'main');
  }

  private setOrdersSubscription(): void {
    this.ordersSubscription = this.orderStatusPreviewsService.orders$.subscribe((orders) => {
      this.orders = orders;
      this.changeDetectorRef.detectChanges();
    });
  }
}
