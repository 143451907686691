<div class="order-content">
  <div class="order-content-image" [style.background-image]="'url(' + logoUrl + ')'"></div>
  <div class="order-content-main">
    <div class="ods-typography-body-s-bold">{{ order?.shop?.name }}</div>
    <div *ngIf="totalDiscountText" class="order-content-main-label ods-typography-microcopy-regular">
      {{ 'got_' | translate }} {{ totalDiscountText }} {{ 'discount_lowercase' | translate }}!
    </div>
    <order-tile-status
      *ngIf="order.status === 'SCHEDULED' || order.status === 'INPROGRESS'"
      [order]="order"
    ></order-tile-status>
    <div class="order-content-main-items">
      <span *ngFor="let item of orderItemsToShow" class="order-content-main-item"
        >x{{ item.quantity }} {{ item.name }}</span
      >
      <span *ngIf="hiddenOrderItemsQuantity > 0" class="order-content-main-item"
        >+ {{ hiddenOrderItemsQuantity }} {{ 'more_products' | translate }}</span
      >
    </div>
  </div>
  <div class="order-content-side">
    <div class="order-content-side-price ods-typography-body-s-bold">{{ totalPrice | currencyPipe }}</div>
    <points-tag
      *ngIf="orderPoints"
      class="order-content-side-points"
      [points]="orderPoints"
      [simplifiedText]="true"
    ></points-tag>
    <order-sm-points *ngIf="smCardPoints" [points]="smCardPoints" [imageSrc]="smLogoSrc"></order-sm-points>
    <div *ngIf="indicators?.length" class="order-content-side-indicators">
      <div *ngFor="let indicator of indicators" class="order-content-side-indicator">
        <svg-icon [svgPath]="indicator.imageSrc" [attr.alt]="indicator.imageAlt"></svg-icon>
      </div>
    </div>
  </div>
</div>
<div class="order-rating" *ngIf="reviewEnabled">
  <span class="order-rating-text ods-typography-body-s-regular">{{ 'your_review' | translate }}:</span>
  <rating
    [size]="20"
    [rating]="order.rating"
    [disabled]="reviewDisabled"
    (changeRating)="onChangeOrderRating($event)"
  ></rating>
</div>
