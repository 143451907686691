import { Component, OnInit, Renderer2, OnDestroy, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { CheckoutSuggestionGroup, Product } from '@box-types';
import { Subscription } from 'rxjs';
import { CheckoutSuggestionDialogService } from './checkout-suggestion-dialog.service';
import { CheckoutSuggestionDialogData, CheckoutSuggestionDialogResponse } from './checkout-suggestion-dialog.types';

@Component({
  selector: 'checkout-suggestion-dialog',
  templateUrl: './checkout-suggestion-dialog.component.html',
  styleUrls: ['./checkout-suggestion-dialog.component.scss'],
  providers: [CheckoutSuggestionDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutSuggestionDialogComponent extends BoxDialogWrapperComponent implements OnInit, OnDestroy {
  public canSubmit: boolean;
  public checkoutSuggestionGroups: CheckoutSuggestionGroup[] = [];

  private groupsSubscription: Subscription;

  constructor(
    public override renderer: Renderer2,
    private dialogRef: MatDialogRef<CheckoutSuggestionDialogComponent>,
    private checkoutSuggestionDialogService: CheckoutSuggestionDialogService,
    @Inject(MAT_DIALOG_DATA) private data: CheckoutSuggestionDialogData
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    const suggestionGroups = this.data.suggestionGroups;
    this.checkoutSuggestionDialogService.normalizeGroupItems(suggestionGroups);
    this.setGroupsSubscription();
  }

  ngOnDestroy(): void {
    if (this.groupsSubscription) this.groupsSubscription.unsubscribe();
  }

  public onProductAdd(group: CheckoutSuggestionGroup, product: Product): void {
    this.checkoutSuggestionDialogService.addProduct(group, product);
    this.canSubmit = this.checkoutSuggestionDialogService.canSubmit();
  }

  public onProductRemove(group: CheckoutSuggestionGroup, product: Product): void {
    this.checkoutSuggestionDialogService.removeProduct(group, product);
    this.canSubmit = this.checkoutSuggestionDialogService.canSubmit();
  }

  public closeDialog(data?: CheckoutSuggestionDialogResponse): void {
    this.dialogRef.close(data);
  }

  public onAddToCart(): void {
    if (!this.canSubmit) return;
    const products = this.checkoutSuggestionDialogService.getProductsToAdd();
    const offers = this.checkoutSuggestionDialogService.getOffersToAdd();
    return this.closeDialog({ addToCart: true, products, offers });
  }

  private setGroupsSubscription(): void {
    this.groupsSubscription = this.checkoutSuggestionDialogService.checkoutSuggestionGroups.subscribe(
      (checkoutSuggestionGroups) => (this.checkoutSuggestionGroups = checkoutSuggestionGroups)
    );
  }
}
