import {
  Component,
  OnInit,
  Inject,
  Renderer2,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BoxDialogWrapperComponent, BOXSliderComponent } from '@box-shared/components';
import { DialogService } from '@box-core/services';
import {
  BankPointsRedeemSlideDialogData,
  BankPointsRedeemSlideDialogResponse
} from '@box-checkout/components/bank-points-redeem-slide-dialog/bank-points-redeem-slide-dialog.interface';
import { translate as t } from '@box-core/services';

@Component({
  selector: 'bank-points-redeem-slide-dialog',
  templateUrl: './bank-points-redeem-slide-dialog.component.html',
  styleUrls: ['./bank-points-redeem-slide-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BankPointsRedeemSlideDialogComponent extends BoxDialogWrapperComponent implements OnInit, AfterViewInit {
  @ViewChild(BOXSliderComponent, { read: ElementRef }) private boxSliderRef: ElementRef<HTMLElement>;
  public title: string;
  public cartPrice: number;
  public totalDiscount: number;
  public availableStep: number;
  public claimableStep: number;
  public selectedSlideStep: number;
  public activeIndex: number;
  public pointsStepsTexts: string[];
  public eurosStepsTexts: string[];
  public maximumStep: number;

  private pointsSteps: number[];
  private eurosSteps: number[];
  private selectedPoints: number;
  private selectedPointsInEuro: number;
  private claimablePoints: number;

  constructor(
    public override renderer: Renderer2,
    private dialogRef: MatDialogRef<BankPointsRedeemSlideDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: BankPointsRedeemSlideDialogData,
    private dialogService: DialogService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.title = this.data.title;
    this.cartPrice = this.data.cartPrice;
    this.pointsSteps = this.data.pointsSteps;
    this.eurosSteps = this.data.eurosSteps;
    this.claimableStep = this.data.availableStep;
    this.availableStep = this.data.availableStep;

    this.pointsStepsTexts = this.pointsSteps.map((step) => step.toString());
    this.eurosStepsTexts = this.eurosSteps.map((step) => `${(step / 100).toString()}${t('currency')}`);
    this.claimablePoints = this.pointsSteps[this.claimableStep];
    this.maximumStep = this.pointsSteps.length - 1;

    this.setSliderToValue(this.data?.selectedPoints);
  }

  override ngAfterViewInit(): void {
    this.boxSliderRef.nativeElement.focus();
    this.changeDetectorRef.detectChanges();
  }

  public closeDialog(data?: BankPointsRedeemSlideDialogResponse): void {
    this.dialogRef.close(data);
  }

  public onSubmit(): void {
    this.closeDialog({ points: this.selectedPoints, euros: this.selectedPointsInEuro });
  }

  // the value is an integer corresponding to the index of the pointsSteps array;
  public onValueChange(value: number): void {
    this.selectedSlideStep = value;
    this.selectedPoints = this.pointsSteps[value];
    this.selectedPointsInEuro = this.eurosSteps[value];
    this.totalDiscount = Math.min(this.selectedPointsInEuro, this.cartPrice);
    this.changeDetectorRef.detectChanges();
    if (this.selectedPoints > this.claimablePoints) {
      this.setSliderToValue(this.claimablePoints);
      this.dialogService.openInfoDialog({
        title: 'points_redemption',
        messages: ['not_enough_bonus_points_for_redemption']
      });
    }
  }

  public onInputChange(value: number): void {
    this.activeIndex = value;
  }

  private setSliderToValue(points = 0): void {
    this.selectedPoints = points;
    const selectedIndex = this.pointsSteps.findIndex((value) => value === points);
    this.selectedPointsInEuro = this.eurosSteps[selectedIndex] ?? 0;
    this.activeIndex = selectedIndex ?? 0;
    this.selectedSlideStep = selectedIndex ?? 0;
    this.totalDiscount = Math.min(this.selectedPointsInEuro, this.cartPrice);
    this.changeDetectorRef.detectChanges();
  }
}
