<div class="shop-page-info-image">
  <image-tile
    [overlayText]="shop?.disabledText"
    [bottomOverlayText]="hasTakeAwayLabel ? 'Take Away' : null"
    [imgUrl]="logoSrc"
    [altText]="shop.name + ' logo'"
  >
  </image-tile>
</div>
<div class="shop-page-info-content">
  <div class="shop-page-info-rating-and-tag">
    <shop-rating [shop]="shop" [showCounter]="true" (click)="onOpenDetails('SHOP_REVIEWS')"></shop-rating>
    <span *ngIf="shop.daas" class="shop-page-info-tag">Delivery By BOX</span>
  </div>
  <header class="shop-page-info-header" [title]="'shop_info' | translate" (click)="onOpenDetails('SHOP_DETAILS')">
    <h1 class="shop-page-info-header-title">{{ shop.name }}</h1>
    <mat-icon class="shop-page-info-header-icon" fontSet="material-icons-round">info_outline</mat-icon>
  </header>
  <p class="shop-page-info-cuisine-text">{{ shop.cuisinesText }}</p>
</div>
