import { Directive, Input, ElementRef, OnInit, Renderer2, OnDestroy, NgZone } from '@angular/core';
import { MatRipple, RippleConfig } from '@angular/material/core';
import { Platform } from '@angular/cdk/platform';
import { FocusMonitor } from '@angular/cdk/a11y';

declare type BoxButtonTheme = 'primary' | 'secondary' | 'muted' | 'insignificant';
declare type BoxButtonSize = 'regular' | 'small';
declare type BoxButtonBorder = 'none';

@Directive({
  selector: '[box-button]',
  providers: [MatRipple]
})
export class BoxButtonDirective implements OnInit, OnDestroy {
  @Input() public theme: BoxButtonTheme = 'primary';
  @Input() public size: BoxButtonSize = 'regular';
  // todo refactor this should not exist
  @Input() public borders: BoxButtonBorder;

  private rippleConfig: RippleConfig;
  private rippleListener: () => void;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private ngZone: NgZone,
    private platform: Platform,
    private focusMonitor: FocusMonitor
  ) {}

  ngOnInit(): void {
    this.setClasses();
    this.wrapContent();
    this.setRipple();
    this.setFocusOverlay();
    this.setFocusMonitor();
  }

  ngOnDestroy(): void {
    if (this.rippleListener) this.rippleListener();
  }

  private setFocusMonitor(): void {
    this.focusMonitor.monitor(this.element);
  }

  private setClasses(): void {
    let themeClass: string;
    let sizeClass: string;
    let borderClass: string;

    this.renderer.addClass(this.element.nativeElement, 'box-button');
    if (this.theme) themeClass = 'box-button-' + this.theme;
    if (themeClass) this.renderer.addClass(this.element.nativeElement, themeClass);
    if (this.size) sizeClass = 'box-button-' + this.size;
    if (sizeClass) this.renderer.addClass(this.element.nativeElement, sizeClass);
    if (this.borders) borderClass = 'box-button-border-' + this.borders;
    if (borderClass) this.renderer.addClass(this.element.nativeElement, borderClass);
  }

  private wrapContent(): void {
    const content = this.element.nativeElement.childNodes;
    const wrapper = this.renderer.createElement('span');
    this.renderer.addClass(wrapper, 'box-button-wrapper');
    const length = content.length;

    for (let i = 0; i < length; i++) {
      // TODO: declarative
      this.renderer.appendChild(wrapper, content[0]);
    }

    this.renderer.appendChild(this.element.nativeElement, wrapper);
  }

  private setRipple(): void {
    this.rippleConfig = {
      color: this.getRippleColor(this.theme),
      persistent: false,
      centered: false,
      animation: {
        enterDuration: 500,
        exitDuration: 250
      }
    };

    const rippleElement = this.renderer.createElement('div');
    this.renderer.addClass(rippleElement, 'box-button-ripple');
    this.renderer.appendChild(this.element.nativeElement, rippleElement);
    const rippleElementRef = new ElementRef(rippleElement);
    const ripple = new MatRipple(rippleElementRef, this.ngZone, this.platform);
    this.rippleListener = this.renderer.listen(rippleElement, 'mousedown', (event) => {
      ripple.launch(event.x, event.y, this.rippleConfig);
    });
  }

  private setFocusOverlay(): void {
    const overlay = this.renderer.createElement('div');
    this.renderer.addClass(overlay, 'box-button-overlay');
    this.renderer.appendChild(this.element.nativeElement, overlay);
  }

  private getRippleColor(theme: BoxButtonTheme): string {
    if (theme === 'primary') return 'rgba(255, 255, 255, 0.15)';
    if (theme === 'secondary') return 'rgb(255, 134, 0, 0.15)';
    if (theme === 'muted') return 'rgba(42, 41, 46, 0.1)';
    if (theme === 'insignificant') return 'rgb(223, 0, 27, 0.15)';
    return 'rgb(255, 134, 0, 0.15)';
  }
}
