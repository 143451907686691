import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { ProductReplacementOption } from '@box-types';
import { Subscription, combineLatest } from 'rxjs';
import { CheckoutProductReplacementDialogResponse } from './checkout-product-replacement-dialog.types';
import { CheckoutStateService } from '@box-checkout/services';

@Component({
  selector: 'checkout-product-replacement-dialog',
  templateUrl: './checkout-product-replacement-dialog.component.html',
  styleUrls: ['./checkout-product-replacement-dialog.component.scss']
})
export class CheckoutProductReplacementDialogComponent extends BoxDialogWrapperComponent implements OnInit, OnDestroy {
  public currentOption: ProductReplacementOption;
  public replacementOptions: ProductReplacementOption[];

  private replacementSubscription: Subscription;

  constructor(
    public override renderer: Renderer2,
    private checkoutStateService: CheckoutStateService,
    private dialogRef: MatDialogRef<CheckoutProductReplacementDialogComponent>
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.setReplacementSubscription();
  }

  ngOnDestroy(): void {
    if (this.replacementSubscription) this.replacementSubscription.unsubscribe();
  }

  public closeDialog(data?: CheckoutProductReplacementDialogResponse) {
    this.dialogRef.close(data);
  }

  public onSubmit(): void {
    this.closeDialog({ save: true, option: this.currentOption });
  }

  private setReplacementSubscription(): void {
    this.replacementSubscription = combineLatest([
      this.checkoutStateService.replacementOption$,
      this.checkoutStateService.replacementOptions$
    ]).subscribe(([option, options]) => {
      this.currentOption = option;
      this.replacementOptions = options;
    });
  }
}
