<div class="instance-wrapper">
  <div class="instance-description">{{ description | translate }}</div>
  <div class="instance-actions">
    <div class="instance-actions-price">{{ instance.price | currencyPipe }}</div>
    <box-quantity
      class="instance-actions-quantity"
      [dense]="true"
      [disabled]="disabled"
      [quantity]="instance.quantity"
      (add)="onAdd()"
      (remove)="onRemove()"
    ></box-quantity>
  </div>
</div>
