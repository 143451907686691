import { Component, ChangeDetectionStrategy, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OrderReceiptOffer, OrderReceiptProduct } from '@box-types';

@Component({
  selector: 'order-receipt-product',
  templateUrl: './order-receipt-product.component.html',
  styleUrls: ['./order-receipt-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderReceiptProductComponent implements OnChanges {
  @Input() public product: OrderReceiptProduct | OrderReceiptOffer;

  public description: string;
  public quantity: number;
  public price: string;

  @HostBinding('class') public hostClass = 'order-receipt-product';

  ngOnChanges(changes: SimpleChanges): void {
    this.product = changes.product.currentValue as OrderReceiptProduct;
    this.description = this.product.description;
    this.quantity = this.product.quantity;
    this.price = this.product.price;
  }
}
