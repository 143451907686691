<div class="shop-page-details-shop-card">
  <div class="shop-page-details-shop-card-container">
    <image-tile class="shop-page-details-shop-card-tile" [imgUrl]="logoUrl" [altText]="name + ' logo'"> </image-tile>
    <div class="shop-page-details-shop-card-info">
      <div class="shop-page-details-shop-card-info-name" [title]="name">{{ name }}</div>
      <div class="shop-page-details-shop-card-info-cuisine-text ods-typography-body-s-regular">{{ cuisineText }}</div>
      <div class="box-divider shop-page-details-shop-card-info-divider"></div>
      <div class="shop-page-details-shop-card-info-payment-types">
        <span class="shop-page-details-shop-card-info-payment-types-text">{{ 'payment_with' | translate }} </span>
        <svg-icon
          *ngFor="let icon of paymentIcons"
          [svgPath]="icon"
          class="shop-page-details-shop-card-info-payment-types-image"
        >
        </svg-icon>
      </div>
    </div>
  </div>
  <div class="shop-page-details-shop-card-footer">
    <div class="shop-page-details-shop-card-footer-text">{{ 'minimum_order' | translate }}</div>
    <div class="shop-page-details-shop-card-footer-price-text">{{ minimumOrderPriceText }}</div>
    <ng-container *ngIf="deliveryFeeText">
      <div class="shop-page-details-shop-card-footer-text">{{ 'delivery_fee_colon' | translate }}</div>
      <div class="shop-page-details-shop-card-footer-price-text">{{ deliveryFeeText }}</div>
    </ng-container>
  </div>
</div>
<div
  *ngIf="mapBackgroundImage"
  class="shop-page-details-map"
  [class.shop-page-details-map--with-multiple-pointers]="
    shopsWithChainDeliveryAnchors?.length && mainShopDeliveryAnchor
  "
  [style.background-image]="mapBackgroundImage"
  (click)="onMapClick()"
></div>
<ng-container *ngIf="shopsWithChainDeliveryAnchors?.length && mainShopDeliveryAnchor; else defaultShopView">
  <div class="shop-page-details-action-block">
    <span class="shop-page-details-shop-info ods-typography-body-s-bold">{{ 'info_for_shop' | translate }}</span>
    <mat-form-field
      class="shop-page-details-store-list"
      [class.shop-page-details-store-list--shake-animation]="shakeAnimationEnabled"
    >
      <mat-select
        #mySelectedStore
        [value]="mainShopDeliveryAnchor"
        disableOptionCentering
        class="material-custom-store-list-panel"
        (openedChange)="onSelectToggle()"
      >
        <mat-select-trigger class="shop-page-details-store-list-selected-text ods-typography-body-s-regular">
          <span>{{ mainShopDeliveryAnchor.text }}</span>
          <mat-icon class="shop-page-details-store-list-selected-icon">{{ storeListSelectIcon }}</mat-icon>
        </mat-select-trigger>
        <mat-option
          *ngFor="let anchor of shopsWithChainDeliveryAnchors; let last = last"
          class="shop-page-details-store-list-item ods-typography-body-s-bold"
          [value]="anchor"
          (click)="onStoreChange(anchor)"
        >
          {{ anchor.text }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-container>
<ng-template #defaultShopView>
  <div class="shop-page-details-action-block">
    <div class="shop-page-details-action-block-text ods-typography-body-s-bold">{{ addressText }}</div>
    <a
      box-button
      theme="secondary"
      borders="none"
      size="small"
      type="button"
      target="blank"
      [attr.href]="addressAnchor"
      class="shop-page-details-action-block-anchor"
    >
      {{ 'instructions_' | translate }}
    </a>
  </div>
  <div class="shop-page-details-action-block">
    <div class="shop-page-details-action-block-text ods-typography-body-s-bold">
      {{ 'shop_telephone' | translate }}, {{ phoneNumber }}
    </div>
    <a
      box-button
      theme="secondary"
      borders="none"
      size="small"
      type="button"
      target="blank"
      [attr.href]="'tel:' + phoneNumber"
      class="shop-page-details-action-block-anchor"
    >
      {{ 'call_' | translate }}
    </a>
  </div>
</ng-template>
<div class="box-divider shop-page-details-divider"></div>
<div class="shop-page-details-timetable">
  <h4 class="shop-page-details-timetable-title">{{ 'delvery_hours' | translate }}</h4>
  <div class="shop-page-details-timetable-dates">
    <div *ngFor="let details of timetableDetails" class="shop-page-details-timetable-date">
      <div class="shop-page-details-timetable-date-day">{{ details.weekDay }}</div>
      <div class="shop-page-details-timetable-date-working-hours">{{ details.workingHours | translate }}</div>
    </div>
  </div>
</div>
<div class="shop-page-details-legal-infos">
  <div *ngIf="brandName" class="shop-page-details-legal-infos-item">
    <span class="shop-page-details-legal-infos-item-description ods-typography-body-s-regular">{{
      'company_name_colon' | translate
    }}</span>
    <span class="shop-page-details-legal-infos-item-value ods-typography-body-s-regular">{{ brandName }}</span>
  </div>
  <div *ngIf="companyHeadquarters" class="shop-page-details-legal-infos-item">
    <span class="shop-page-details-legal-infos-item-description ods-typography-body-s-regular">{{
      'company_headquarters' | translate
    }}</span>
    <span class="shop-page-details-legal-infos-item-value ods-typography-body-s-regular">{{
      companyHeadquarters
    }}</span>
  </div>
  <div *ngIf="taxNumber" class="shop-page-details-legal-infos-item">
    <span class="shop-page-details-legal-infos-item-description ods-typography-body-s-regular">{{
      'afm_colon' | translate
    }}</span>
    <span class="shop-page-details-legal-infos-item-value ods-typography-body-s-regular">{{ taxNumber }}</span>
  </div>
  <div *ngIf="commercialRegister" class="shop-page-details-legal-infos-item">
    <span class="shop-page-details-legal-infos-item-description ods-typography-body-s-regular">{{
      'gemh_colon' | translate
    }}</span>
    <span class="shop-page-details-legal-infos-item-value ods-typography-body-s-regular">{{ commercialRegister }}</span>
  </div>
  <div *ngIf="addressText" class="shop-page-details-legal-infos-item">
    <span class="shop-page-details-legal-infos-item-description ods-typography-body-s-regular">{{
      'shop_address' | translate
    }}</span>
    <span class="shop-page-details-legal-infos-item-value ods-typography-body-s-regular">{{ addressText }}</span>
  </div>
</div>
<div class="box-divider shop-page-details-divider"></div>
<div class="shop-page-details-applicable-laws-compliance">
  <span class="ods-typography-microcopy-bold">{{ 'applicable_laws_compliance' | translate }}</span>
</div>
