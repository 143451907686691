import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Ingredient } from '@box-types';
import { applyPercentageDiscount } from '@box/utils';

@Component({
  selector: 'product-myo-choice',
  templateUrl: './product-myo-choice.component.html',
  styleUrls: ['./product-myo-choice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductMYOChoiceComponent implements OnChanges {
  @Input() public showPlus: boolean;
  @Input() public choice: Ingredient;
  @Input() public discount: number;

  public title: string;
  public envFee: number;
  public price: number;

  @HostBinding('class') public hostClass = 'product-myo-choice';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showPlus) this.showPlus = changes.showPlus.currentValue as boolean;
    if (changes.choice) {
      this.choice = changes.choice.currentValue as Ingredient;
      this.title = this.choice.title;
      this.envFee = this.choice.envFee;
    }
    if (changes.discount) {
      this.discount = changes.discount.currentValue as number;
    }

    this.price = this.getPrice();
  }

  private getPrice(): number {
    const changeInPrice = this.choice.changeInPrice;
    if (!this.discount) return changeInPrice;
    const envFee = this.choice.envFee ?? 0;
    return applyPercentageDiscount(changeInPrice - envFee, this.discount) + envFee;
  }
}
