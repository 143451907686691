import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigurationService } from '@box-core/services';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { Router } from '@angular/router';
import { getGBSegmentSkin, generateImageSrc } from '@box/utils';
import { LanguageService } from '@box-core/services/language.service';
import { GetTextByKeyType } from '@box-types';

const DEFAULT_IMAGE = '/assets/images/rewards/wu-promo-dialog-header.svg'; // not multilingual

let t: GetTextByKeyType; // for the component to use

@Component({
  selector: 'gb-promo-dialog',
  templateUrl: './gb-promo-dialog.component.html',
  styleUrls: ['./gb-promo-dialog.component.scss']
})
export class GBPromoDialogComponent extends BoxDialogWrapperComponent implements OnInit {
  public title: string;
  public imageUrl: string = DEFAULT_IMAGE;

  private segment: string;

  constructor(
    public override renderer: Renderer2,
    private dialogRef: MatDialogRef<GBPromoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { segment: string },
    private configService: ConfigurationService,
    private router: Router,
    private languageService: LanguageService
  ) {
    super(renderer);
    t = this.languageService.getTextByKey.bind(this.languageService);
  }

  ngOnInit(): void {
    this.segment = this.data.segment;
    this.setGBRewardsConfigurationData(this.segment);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public onOrderNowClick(): void {
    this.closeDialog();
    void this.router.navigate(['/discover']);
  }

  private setGBRewardsConfigurationData(segment: string): void {
    const config = this.configService.getConfiguration();
    if (!config?.rewards) return;
    const skin = getGBSegmentSkin(config, segment);
    if (!skin) {
      const defaultName = this.languageService.getTextByKey('our_wu_users');
      this.title = t('exclusively_for', { _SEGMENT_USERS: defaultName });
      this.imageUrl = DEFAULT_IMAGE;
      return;
    }
    this.title = t('exclusively_for', { _SEGMENT_USERS: skin.name });
    this.imageUrl = generateImageSrc(skin.webInformativeModalBanner) ?? DEFAULT_IMAGE;
  }
}
