import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Shop } from '@box-types';
import {
  formatReviewsCounter,
  ShopRatingStatus,
  getShopRating,
  getFormattedShopRating,
  getShopRatingStatus
} from '@box/utils';

@Component({
  selector: 'shop-rating',
  templateUrl: './shop-rating.component.html',
  styleUrls: ['./shop-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopRatingComponent implements OnChanges {
  @Input() public shop: Shop;
  @Input() public showCounter: boolean;

  public rating: number;
  public ratingText: string;
  public ratingCounterText: string;
  public status: ShopRatingStatus;

  @HostBinding('class') public hostClass = 'shop-rating';
  @HostBinding('class.shop-rating-excellent') public isRatingExcellent: boolean;
  @HostBinding('class.shop-rating-good') public isRatingGood: boolean;
  @HostBinding('class.shop-rating-ok') public isRatingOk: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showCounter) this.showCounter = changes.showCounter.currentValue as boolean;
    if (changes.shop) {
      this.shop = changes.shop.currentValue as Shop;
      this.rating = getShopRating(this.shop);
      this.ratingText = getFormattedShopRating(this.shop);
      this.ratingCounterText = formatReviewsCounter(this.shop.ratingCounter);
      this.status = getShopRatingStatus(this.shop);
      this.isRatingExcellent = this.status === 'EXCELLENT';
      this.isRatingGood = this.status === 'GOOD';
      this.isRatingOk = this.status === 'OK';
    }
  }
}
