import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialogConfig } from '@angular/material/dialog';
import { Order, Shop, GAPromotionConfig } from '@box-types';
import { ShopOrdersDialogComponent } from '@box-orders-widget/components';
import { Subscription } from 'rxjs';
import { getOrderDeliveryStatus, getCollectionShopOrderImpressionGAConfig } from '@box/utils';
import { ShopOrdersService } from '@box-orders-widget/components/shop-orders/shop-orders.service';
import { AnalyticsService, DialogService } from '@box-core/services';

@Component({
  selector: 'shop-orders',
  templateUrl: './shop-orders.component.html',
  styleUrls: ['./shop-orders.component.scss'],
  providers: [ShopOrdersService]
})
export class ShopOrdersComponent implements OnInit, OnDestroy {
  @Input() public orders: Order[] = [];
  @Input() public title: string;
  @Input() public shop: Shop;
  public latestOrders: Order[] = [];
  public remainingOrders: Order[] = [];
  private layoutSubscription: Subscription;

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private shopOrdersService: ShopOrdersService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.latestOrders = this.orders.slice(0, 2);
    this.remainingOrders = this.orders.slice(2);
    this.checkRouteParams();
  }

  ngOnDestroy(): void {
    if (this.layoutSubscription) this.layoutSubscription.unsubscribe();
  }

  // TODO Find a better way to order from previous orders
  private checkRouteParams(): void {
    const vanityUrl = this.activatedRoute.snapshot.params.vanityUrl as string;
    const locationKey = this.activatedRoute.snapshot.params.locationKey as string;
    const friendlyId: string = this.activatedRoute.snapshot.queryParams.fromOrder as string;
    if (!friendlyId) return;
    this.location.replaceState(`/delivery/${locationKey}/${vanityUrl}`);
    const order: Order = this.orders.find((o) => o.friendlyId === friendlyId);
    this.shopOrdersService.orderToCart(order);
  }

  public onOrderClick(order: Order): void {
    this.triggerAnalyticsEvent(order);
    const status = getOrderDeliveryStatus(order);
    if (status === 'SCHEDULED') return this.shopOrdersService.handleAcceptedOrder(order);
    if (status === 'INPROGRESS') return this.shopOrdersService.handleAcceptedOrder(order);
    if (status === 'COMPLETED') return this.shopOrdersService.handleCompletedOrder(order);
    return this.shopOrdersService.handleCompletedOrder(order);
  }

  public openOrdersDialog(): void {
    const dialogConfig: MatDialogConfig = {
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'box-dialog',
      data: { orders: this.remainingOrders, shop: this.shop }
    };

    const dialogRef = this.dialogService.openDialog(ShopOrdersDialogComponent, dialogConfig);
    dialogRef.afterOpened().subscribe(() => this.triggerAnalyticsModalEvent());
    dialogRef.afterClosed().subscribe((data: { addToCart: boolean; goToOrder: boolean; order: Order }) => {
      if (!data) return undefined;
      if (data.addToCart) return this.shopOrdersService.handleCompletedOrder(data.order);
      if (data.goToOrder) return this.shopOrdersService.handleAcceptedOrder(data.order);
    });
  }

  private triggerAnalyticsModalEvent(): void {
    const gaConfig = {
      creative_name: '',
      creative_slot: 'recent_orders_modal',
      promotion_id: '',
      promotion_name: 'recent_orders_modal'
    } as GAPromotionConfig;
    this.analyticsService.addGAEcommerceEvent('view_promotion', gaConfig);
  }

  private triggerAnalyticsEvent(order: Order): void {
    const options = {
      creativeName: this.title,
      creativeSlot: 'shop',
      promotionName: 'recent_orders_shop',
      itemListName: 'recent_orders_shop',
      orders: this.latestOrders,
      order: order,
      shop: this.shop
    };
    const gaConfig = getCollectionShopOrderImpressionGAConfig(options);
    this.analyticsService.addGAEcommerceEvent('select_promotion', gaConfig);
  }
}
