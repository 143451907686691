import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Order, GAItemRatingConfig } from '@box-types';
import { AnalyticsService, DialogService } from '@box-core/services';
import { Subscription, map } from 'rxjs';
import { ReviewDialogComponent } from '@box-rating-widget/components';
import {
  ReviewDialogData,
  ReviewDialogResponse
} from '@box-rating-widget/components/review-dialog/review-dialog.types';
import { OrderCompletionRatingsService } from './order-completion-ratings.service';

@Component({
  selector: 'order-completion-ratings',
  templateUrl: './order-completion-ratings.component.html',
  styleUrls: ['./order-completion-ratings.component.scss'],
  providers: [OrderCompletionRatingsService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderCompletionRatingsComponent implements OnInit, OnDestroy {
  /** We are gonna extract the current viewing order from the list due to the fact that
   * the user can already review it from the Order Status Rating element */
  @Input() public excludedOrder: Order;

  public visibleOrders: Order[];
  public expanded: boolean;
  public showExpandButton: boolean;
  public expandButtonText: string;

  private orders: Order[];
  private ordersSubscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private orderCompletionRatingsService: OrderCompletionRatingsService,
    private dialogService: DialogService,
    private analyticsService: AnalyticsService
  ) {}

  @HostBinding('class') public hostClass = 'order-completion-ratings';

  ngOnInit(): void {
    this.setOrdersSubscription();
    this.fetchOrders();
  }

  ngOnDestroy(): void {
    this.ordersSubscription?.unsubscribe();
  }

  public onMoreToggle(): void {
    this.expanded = !this.expanded;
    this.expandButtonText = this.orderCompletionRatingsService.getExpandButtonText(this.expanded);
    this.setVisibleOrders();
  }

  public onRateOrder(order: Order, rating: number): void {
    this.triggerAnalyticsRatingEvent(rating);
    const dialogConfig: MatDialogConfig<ReviewDialogData> = {
      panelClass: 'box-dialog',
      data: { rating, order, source: 'order_completed' }
    };
    this.dialogService
      .openDialog<ReviewDialogComponent, ReviewDialogResponse>(ReviewDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((reviewResponse) => {
        if (!reviewResponse?.review) return;
        this.orderCompletionRatingsService.removeOrder(order);
        const orderRating = reviewResponse.review.rating;
        this.orderCompletionRatingsService.updateOrderHistoryAfterRate(order, orderRating);
      });
  }

  private setOrdersSubscription(): void {
    this.ordersSubscription = this.orderCompletionRatingsService.orders$
      .pipe(
        map((orders) => {
          const excludedOrderFriendlyId = this.excludedOrder?.friendlyId;
          if (!excludedOrderFriendlyId) return orders;
          return orders.filter((order) => order.friendlyId !== excludedOrderFriendlyId);
        })
      )
      .subscribe((orders) => {
        this.orders = orders;
        this.setVisibleOrders();
        this.showExpandButton = orders.length > 5;
        this.expandButtonText = this.orderCompletionRatingsService.getExpandButtonText(this.expanded);
        this.changeDetectorRef.detectChanges();
      });
  }

  private fetchOrders(): void {
    this.orderCompletionRatingsService
      .fetchOrders()
      .subscribe((orders) => this.orderCompletionRatingsService.setOrders(orders));
  }

  private setVisibleOrders(): void {
    const sliceIndex = this.expanded ? 10 : 5;
    this.visibleOrders = this.orders.slice(0, sliceIndex);
  }

  private triggerAnalyticsRatingEvent(rating: number): void {
    const gaConfig = {
      rating: String(rating),
      source: 'order_completed'
    } as GAItemRatingConfig;
    this.analyticsService.addGACustomEvent('item_rating', gaConfig);
  }
}
