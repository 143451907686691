import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subscription, combineLatest, Observable, mapTo, merge } from 'rxjs';
import { delay, filter, map } from 'rxjs/operators';

import { LoaderService } from '@box-core/services';
import { FadeInOut } from '@box-core/animations';
import {
  Router,
  RouterEvent,
  Event,
  NavigationEnd,
  NavigationStart,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import BOXVersion from '@box-env/version.json';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'box-root',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss'],
  animations: [FadeInOut]
})
export class BoxComponent implements OnInit, OnDestroy {
  public showLoader = false;

  private loaderSubscription: Subscription;
  private privacySubscription: Subscription;

  constructor(private router: Router, private loaderService: LoaderService, private dialogRef: MatDialog) {}

  @HostBinding('attr.app-version') public appVersion = BOXVersion.version;
  @HostBinding('attr.app-build-number') public appBuildNumber = BOXVersion.build;

  ngOnInit(): void {
    this.setLoaderSubscription();
  }

  ngOnDestroy(): void {
    this.loaderSubscription?.unsubscribe();
    this.privacySubscription?.unsubscribe();
  }

  private setLoaderSubscription(): void {
    this.loaderSubscription = combineLatest([
      this.isDialogOpen(),
      this.isNavigating(),
      this.loaderService.loading
    ]).subscribe(([isDialogOpen, navigating, loading]) => {
      if (loading) {
        this.showLoader = true;
      } else if (isDialogOpen) {
        this.showLoader = false;
      } else if (navigating) {
        this.showLoader = true;
      } else {
        this.showLoader = false;
      }
    });
  }

  private isNavigating(): Observable<boolean> {
    // TEMP FIX: Added delay in order to avoid the fast route change issue that causes ExpressionChangedAfterItHasBeenCheckedError
    return this.router.events.pipe(
      delay(0),
      filter(
        (routerEvent: Event) =>
          routerEvent instanceof NavigationStart ||
          routerEvent instanceof NavigationCancel ||
          routerEvent instanceof NavigationError ||
          routerEvent instanceof NavigationEnd
      ),
      map((routerEvent: RouterEvent) => {
        return routerEvent instanceof NavigationStart;
      })
    );
  }

  private isDialogOpen(): Observable<boolean> {
    return merge(this.dialogRef.afterOpened.pipe(mapTo(true)), this.dialogRef.afterAllClosed.pipe(mapTo(false)));
  }
}
