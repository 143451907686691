import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { separate, numbersOnly } from '@box/utils';

@Directive({
  selector: 'input[matInputDateFormat]',
  providers: [
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: MatInputDateFormatDirective },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatInputDateFormatDirective),
      multi: true
    }
  ]
})
export class MatInputDateFormatDirective {
  @Input() public shouldReturnFormattedValue = false;
  @Input() public dateFormat: 'DD/MM' | 'MM/YY' | 'DD/MM/YYYY' | 'DD/MM/YY';

  @Input()
  set value(value: string) {
    this._value = value;
  }

  get value(): string {
    if (this.shouldReturnFormattedValue) return this._value;
    return numbersOnly(this._value);
  }

  private _value: string;

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  private getFormattedString(input: string): string {
    if (!input?.length) return '';
    const clearInput = numbersOnly(input);
    switch (this.dateFormat) {
      case 'DD/MM': {
        const restrictedLengthInput = clearInput.slice(0, 4);
        return separate(restrictedLengthInput, 1, 2, '/');
      }
      case 'MM/YY': {
        const restrictedLengthInput = clearInput.slice(0, 4);
        return separate(restrictedLengthInput, 1, 2, '/');
      }
      case 'DD/MM/YY': {
        const restrictedLengthInput = clearInput.slice(0, 6);
        return separate(restrictedLengthInput, 2, 2, '/');
      }
      case 'DD/MM/YYYY': {
        const restrictedLengthInput = clearInput.slice(0, 8);
        return separate(restrictedLengthInput, 2, 2, '/');
      }
    }
  }

  @HostListener('keydown.backspace', ['$event.target.value'])
  onBackSpace(value: string): void {
    if (!value?.length || value[value.length - 1] !== '/') return;
    this.elementRef.nativeElement.value = value.substring(0, value.length - 1);
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    const formattedValue = this.getFormattedString(value);
    this.elementRef.nativeElement.value = formattedValue;
    this._value = formattedValue;
    this._onChange(formattedValue); // notify Angular Validators
  }

  // ControlValueAccessor API Start

  // eslint-disable-next-line
  _onChange(value: any): void {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(value: any): void {
    const formattedValue = this.getFormattedString(value as string);
    this._value = formattedValue;
    this.elementRef.nativeElement.value = formattedValue;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: (value: any) => void): void {
    // eslint-disable-line
    this._onChange = fn;
  }

  registerOnTouched(value: any): void {} // eslint-disable-line

  // ControlValueAccessor API End
}
