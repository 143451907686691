import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Subscription, combineLatest, switchMap } from 'rxjs';
import {
  Shop,
  MarketCardConnectDialogResponse,
  MarketCardDetails,
  MarketCardValidationConfig,
  MarketCardValidationDialogResponse,
  MarketOrderCheck,
  MarketCardValidationDialogData,
  SMLoyaltyConfiguration
} from '@box-types';
import { ConfigurationService, UserService } from '@box-core/services';
import { tap } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import {
  MarketCardConnectDialogComponent,
  MarketCardValidationDialogComponent,
  MarketCardDialogComponent
} from '@box-market-widget/components';
import { BoxInfoDialogComponent } from '@box-shared/components';
import { generateImageSrc } from '@box/utils';
import { CheckoutStateService, CheckoutService } from '@box-checkout/services';
import { LanguageService } from '@box-core/services/language.service';

@Component({
  selector: 'checkout-market-points',
  templateUrl: './checkout-market-points.component.html',
  styleUrls: ['./checkout-market-points.component.scss']
})
export class CheckoutMarketPointsComponent implements OnInit, OnDestroy {
  @Input() public shop: Shop;

  public state: string;
  public shopName: string;
  public shopImageURL: string;
  public cardName: string;
  public cardImageURL: string;
  public cardPoints: number;
  public isCardConnected: boolean;
  public pointsToRedeem: number;
  public priceDiscount: number;
  public pointsFromOrder: number;
  public hasPendingOrders: boolean;
  public orderPrice: number;
  public bannerText: string;
  public pointsChecked: boolean;
  public disabled: boolean;
  public marketCard: MarketCardDetails;
  public checkoutPoints: MarketOrderCheck;
  public totalPoints: number;

  private integratorName: string;
  private integratorOptions: SMLoyaltyConfiguration;
  private pointsSubscription: Subscription;

  constructor(
    private configService: ConfigurationService,
    private decimalPipe: DecimalPipe,
    private dialog: MatDialog,
    private userService: UserService,
    private checkoutStateService: CheckoutStateService,
    private checkoutService: CheckoutService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.setIntegratorData();
    this.marketCard = this.getMarketCardDetails();
    this.isCardConnected = Boolean(this.marketCard);
    this.setLoyaltySubscriptions();
  }

  ngOnDestroy(): void {
    if (this.pointsSubscription) this.pointsSubscription.unsubscribe();
  }

  public onModeChange(event: MatSlideToggleChange): void {
    if (this.hasPendingOrders) return this.openPendingOrderDialog();
    this.checkoutStateService.setMarketPointsChecked(event.checked);
  }

  public onCardConnect(): void {
    this.openMarketCardConnectDialog();
  }

  private getMarketCardDetails(): MarketCardDetails {
    const loyaltyCards = this.userService.getLoayltyCards();
    return loyaltyCards.find((c) => c.name === this.integratorName);
  }

  private setLoyaltySubscriptions(): void {
    this.pointsSubscription = combineLatest([
      this.checkoutStateService.marketPointsChecked$.pipe(tap((c) => (this.pointsChecked = c))),
      this.checkoutStateService.marketOrderCheck$.pipe(tap((cp) => this.setStateWithCheckMarketOrder(cp)))
    ]).subscribe(() => {
      this.isCardConnected = Boolean(this.marketCard);
      this.state = this.getState();
      this.bannerText = this.generateBannerText();
    });
  }

  private setStateWithCheckMarketOrder(marketOrderCheck: MarketOrderCheck): void {
    this.checkoutPoints = marketOrderCheck;
    this.disabled = !marketOrderCheck?.hasLoyaltyDiscount;
    this.hasPendingOrders = marketOrderCheck?.hasPendingOrders ?? false;
    this.pointsFromOrder = marketOrderCheck?.pointsFromOrder ?? 0;
    this.orderPrice = marketOrderCheck?.orderPrice ?? this.orderPrice;
    this.cardPoints = marketOrderCheck?.cardPoints ?? 0;
    const marketCardPoints = this.marketCard ? this.marketCard.points : 0;
    this.totalPoints = marketCardPoints + (marketOrderCheck?.pointsFromOrder ?? 0);

    if (marketOrderCheck?.hasLoyaltyDiscount) {
      this.pointsToRedeem = marketOrderCheck?.pointsToRedeem ?? 0;
      this.priceDiscount = marketOrderCheck?.priceDiscount ?? 0;
    } else {
      this.pointsToRedeem = this.integratorOptions.pointsRedeemRate;
      this.priceDiscount = this.integratorOptions.discountRate;
    }
  }

  private getIntegratorOptions(integrator: string): SMLoyaltyConfiguration {
    const boxConfig = this.configService.getConfiguration();
    if (!boxConfig) return undefined;
    const smLoyaltyInfo = boxConfig.smLoyaltyInfo || [];
    return smLoyaltyInfo.find((info) => info.integrator === integrator);
  }

  private setIntegratorData(): void {
    this.integratorName = this.shop.integrator.company;
    this.integratorOptions = this.getIntegratorOptions(this.integratorName);
    this.shopImageURL = generateImageSrc(this.integratorOptions.checkoutDiscountLogo);
    this.cardName = this.integratorOptions.shortCardName;
    this.cardImageURL = this.getCardImageURL();
    this.shopName = this.integratorOptions.smName;
  }

  private getCardImageURL(): string {
    const user = this.userService.getUser();
    const integratorName = this.shop.integrator.company;
    const loyaltyCard = user.loyaltyCards.find((card) => card.name === integratorName);
    const localKritikosGuestCard = window.localStorage.getItem('Box:kritikosGuestCard');
    // todo when going back to checkout after card deletion broken ui
    // possible solution all cards have guestCardPlaceholder
    const showCard = !loyaltyCard || localKritikosGuestCard;
    if (showCard) return generateImageSrc(this.integratorOptions.guestCardPlaceholder);
    return '';
  }

  private generateBannerText(): string {
    if (this.state === 'disconnected') {
      return this.languageService.getTextByKey('connect_your_card_with_the_box', { _CARD_NAME: this.cardName });
    }
    const points = this.decimalPipe.transform(this.pointsToRedeem, '1.0-0');
    const price = this.decimalPipe.transform(this.priceDiscount / 100, '1.0-0');

    if (this.state === 'connected') {
      return this.languageService.getTextByKey('redeem_points_and_earn_discount', {
        _POINTS: points,
        _CARD_NAME: this.cardName,
        _PRICE: price
      });
    }

    return this.languageService.getTextByKey('redeeming_points_and_earning_discount', {
      _POINTS: points,
      _CARD_NAME: this.cardName,
      _PRICE: price
    });
  }

  private getState(): string {
    if (!this.isCardConnected) return 'disconnected';
    if (!this.pointsChecked) return 'connected';
    if (this.pointsChecked) return 'active';
  }

  private openMarketCardConnectDialog(): void {
    const dialogConfig: MatDialogConfig = {
      restoreFocus: false,
      autoFocus: false,
      panelClass: 'box-dialog-fit-content',
      data: {
        shop: this.shop,
        config: { canSearch: true, canCreate: false }
      }
    };

    this.dialog
      .open(MarketCardConnectDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((data: MarketCardConnectDialogResponse) => {
        if (!data) return undefined;
        if (data.search) return this.openMarketCardValidationDialog(data.validationConfig, data.saveCard);
      });
  }

  private openMarketCardValidationDialog(validationConfig: MarketCardValidationConfig, saveCard: boolean): void {
    const dialogConfig: MatDialogConfig = {
      restoreFocus: false,
      autoFocus: false,
      panelClass: 'box-dialog-fit-content',
      data: { shop: this.shop, validationConfig, saveCard, reason: 'login' } as MarketCardValidationDialogData
    };

    this.dialog
      .open(MarketCardValidationDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((data: MarketCardValidationDialogResponse) => {
        if (!data) return undefined;
        if (data.success) {
          if (data.save) {
            this.userService.saveLoyaltyCard(data.cardDetails, false);
          } else {
            this.userService.saveTempLoyaltyCard(data.cardDetails);
          }
          this.marketCard = data.cardDetails;
          this.isCardConnected = Boolean(this.marketCard);
          this.state = this.getState();
          return this.openMarketCardDialog(data.cardDetails);
        }
      });
  }

  private openMarketCardDialog(cardDetails: MarketCardDetails): void {
    const dialogConfig: MatDialogConfig = {
      restoreFocus: false,
      autoFocus: false,
      panelClass: 'box-dialog-fit-content',
      data: { shop: this.shop, cardDetails }
    };

    this.dialog
      .open(MarketCardDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(switchMap(() => this.checkoutService.checkMarketOrder()))
      .subscribe();
  }

  private openPendingOrderDialog(): void {
    const dialogConfig: MatDialogConfig = {
      restoreFocus: false,
      autoFocus: false,
      data: {
        title: 'pending_points_redemption',
        messages: ['a_previous_requested_order_has_not_yet_been_completed']
      }
    };

    this.dialog
      .open(BoxInfoDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(() => (this.pointsChecked = false));
  }
}
