import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { Subscription } from 'rxjs';
import { NavItem } from '@box-types';
import { MatMenuTrigger } from '@angular/material/menu';
import { CosmoteIDService, UserService } from '@box-core/services';
import { filterValidNavItemsForUser } from '@box/utils';
import { profileNavItems } from '@box-configs/nav-items.options';

type MenuTile = 'home' | 'food' | 'groceries' | 'orders' | 'profile';

@Component({
  selector: 'box-mobile-nav-footer',
  templateUrl: './box-mobile-nav-footer.component.html',
  styleUrls: ['./box-mobile-nav-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxMobileNavFooterComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild('dot') dot: ElementRef;

  public profileMatMenuItems: NavItem[];
  public activeTile: MenuTile;

  private routerSubscription: Subscription;

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private cosmoteIDService: CosmoteIDService
  ) {}

  ngOnInit(): void {
    this.setProfileMatMenuItems();
    this.setRoutingEventsSubscriptions();
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.handleMenuActivation(this.router.url);
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }

  public profileTileStateActive(active: boolean): void {
    if (active) {
      this.handleMenuActivation('account/settings');
    } else {
      this.handleMenuActivation(this.router.url);
    }
  }

  private setRoutingEventsSubscriptions(): void {
    this.routerSubscription = this.router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationEnd) this.handleMenuActivation(routerEvent.url);
    });
  }

  private handleMenuActivation(url: string): void {
    this.setActivePage(url);
    this.shiftDot();
    this.changeDetectorRef.detectChanges();
  }

  private setActivePage(url: string): void {
    if (!url) return;
    if (url.includes('/home')) {
      this.activeTile = 'home';
    } else if (url.includes('/discover?vertical=food')) {
      this.activeTile = 'food';
    } else if (url.includes('/discover?vertical=groceries')) {
      this.activeTile = 'groceries';
    } else if (url.includes('/account/history')) {
      this.activeTile = 'orders';
    } else if (this.isInProfilePage(url)) {
      this.activeTile = 'profile';
    } else {
      this.activeTile = null;
    }
  }

  private shiftDot(): void {
    if (!this.dot?.nativeElement) return;

    let marginLeftStyle: string;
    switch (this.activeTile) {
      case 'home':
        marginLeftStyle = 'calc(10% - 0.1rem)';
        break;
      case 'food':
        marginLeftStyle = 'calc(30% - 0.1rem)';
        break;
      case 'groceries':
        marginLeftStyle = 'calc(50% - 0.1rem)';
        break;
      case 'orders':
        marginLeftStyle = 'calc(70% - 0.1rem)';
        break;
      case 'profile':
        marginLeftStyle = 'calc(90% - 0.1rem)';
        break;
    }

    this.dot.nativeElement.style.setProperty('margin-left', marginLeftStyle);
  }

  private isInProfilePage(url: string): boolean {
    return this.profileMatMenuItems.some((item) => item.routerLink?.includes(url));
  }

  private setProfileMatMenuItems() {
    const isGuest = this.userService.isGuest;
    const filteredMenuItems = filterValidNavItemsForUser(profileNavItems, isGuest);
    this.profileMatMenuItems = filteredMenuItems.filter((item) => item.routerLink !== '/account/history');
  }

  public onClick(menuItem: NavItem): void {
    if (menuItem?.clickAction) {
      this.cosmoteIDService.redirectToCosmoteId();
      return;
    }
    if (menuItem?.routerLink) void this.router.navigate([menuItem.routerLink]);
  }
}
