import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { Address, Shop, APIError, DeliveryMethod } from '@box-types';
import {
  AddressesService,
  DialogService,
  LoaderService,
  DeliveryMethodService,
  ShopsService,
  ShopService,
  CartService
} from '@box-core/services';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Subscription, combineLatest } from 'rxjs';
import { finalize, skip } from 'rxjs/operators';
import { getAddressDescription, isAddressReadyForDelivery } from '@box/utils';
import { Router } from '@angular/router';

@Component({
  selector: 'checkout-addresses',
  templateUrl: './checkout-addresses.component.html',
  styleUrls: ['./checkout-addresses.component.scss']
})
export class CheckoutAddressesComponent implements OnInit, OnDestroy {
  @ViewChild('addressesPanel') private addressesPanel: MatExpansionPanel;
  @Input() public shop: Shop;

  public address: Address;
  public addresses: Address[];
  public disabled: boolean;
  public deliveryMethod: DeliveryMethod;
  public shopAddress: string;

  private addressesSubscription: Subscription;
  private addressSubscription: Subscription;
  private deliveryMethodSubscription: Subscription;

  constructor(
    private addressesService: AddressesService,
    private loaderService: LoaderService,
    private shopService: ShopService,
    private shopsService: ShopsService,
    private dialogService: DialogService,
    private deliveryMethodService: DeliveryMethodService,
    private cartService: CartService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setAddressesSubscription();
    this.setAddressSubscription();
    this.setDeliveryMethodSubscription();
    this.shopAddress = getAddressDescription(this.shop.address);
  }

  ngOnDestroy(): void {
    this.addressesSubscription?.unsubscribe();
    this.addressSubscription?.unsubscribe();
    this.deliveryMethodSubscription?.unsubscribe();
  }

  private disableAddresses(): void {
    this.disabled = true;
    if (!this.addressesPanel) return;
    this.addressesPanel.close();
  }

  private enableAddresses(): void {
    this.disabled = false;
  }

  public checkIfShopCanDeliverToAddress(address: Address): void {
    this.loaderService.setState(true);
    this.shopsService
      .canDeliverToAddress(this.shop._id, address)
      .pipe(finalize(() => this.loaderService.setState(false)))
      .subscribe({
        next: (shop) => this.handleCanDeliverResponse(shop, address),
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  private handleCanDeliverResponse(shop: Partial<Shop> & { found: boolean }, address: Address): void {
    if (!shop?.found) return this.openAddressDeliveryInability(address);
    const updatedShop = { ...this.shopService.getShop(), ...shop };
    this.shop = updatedShop;
    this.shopService.setShop(updatedShop);
    this.addressesPanel.close();
  }

  private openAddressDeliveryInability(address: Address): void {
    this.dialogService
      .openConfirmDialog({
        title: 'the_address_is_too_far_away',
        messages: ['unfortunately_the_store_does_not_serve_this_address'],
        confirmText: 'change_',
        cancelText: 'delete_'
      })
      .afterClosed()
      .subscribe((data: { accepted: boolean }) => {
        if (!data?.accepted) this.onResetAddress();
      });
  }

  public onResetAddress(): void {
    this.shopService.clearMenuItemsQuantities();
    this.cartService.clearCartAndShop();
    void this.router.navigate(['/home']);
  }

  private setDeliveryMethodSubscription(): void {
    this.deliveryMethodSubscription = this.deliveryMethodService.deliveryMethod.subscribe((method) => {
      this.deliveryMethod = method;
      if (method === 'takeAway') {
        this.disableAddresses();
      } else {
        this.enableAddresses();
      }
    });
  }

  private setAddressesSubscription(): void {
    this.addressesSubscription = combineLatest([
      this.addressesService.address$,
      this.addressesService.addresses$
    ]).subscribe(([selectedAddress, addresses]) => {
      this.address = selectedAddress;
      this.addresses = this.getCheckoutAddresses(selectedAddress, addresses);
    });
  }

  private getCheckoutAddresses(selectedAddress: Address, addresses: Address[]): Address[] {
    return addresses.filter((addr) => {
      const allRequiredPropsArePresent = isAddressReadyForDelivery(addr);
      const addressIsNotTheSelectedOne = addr.addressId !== selectedAddress.addressId;
      return allRequiredPropsArePresent && addressIsNotTheSelectedOne;
    });
  }

  private setAddressSubscription(): void {
    /* since in the ngOnDestroy invocation in shop page runs after the address.guard is executed, we have the following scenario:
     * 1. (edge case) user edits his address from address.guard before going to checkout and enters one that is not served by the shop.
     * 2. the address subscription in the shop page is fired, and we show the user an error dialog
     * 3. the checkout subscription is also fired and we show the user another error dialog
     * 4. to avoid this, and not run the same check twice this skip is introduced
     * (when the user directly visits the checkout page this check doesn't run) */
    this.addressSubscription = this.addressesService.address$.pipe(skip(1)).subscribe((address) => {
      this.checkIfShopCanDeliverToAddress(address);
    });
  }

  public onSelectAddress(address: Address): void {
    this.addressesService.setAddress(address);
  }

  public onAddNewAddress(): void {
    this.addressesService.initiateAddFullAddressDialogFlow$().subscribe();
  }
}
