import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Contest } from '@box-types';
import { SwiperOptions } from 'swiper/types';
import { Mousewheel, Navigation } from 'swiper/modules';
import { HomeSection } from '@box/utils';

@Component({
  selector: 'contests-section',
  templateUrl: './contests-section.component.html',
  styleUrls: ['./contests-section.component.scss']
})
export class ContestsSectionComponent implements OnInit, AfterViewInit {
  @ViewChild('nextBtn', { static: true }) private nextBtn: MatButton;
  @ViewChild('prevBtn', { static: true }) private prevBtn: MatButton;
  @ViewChild('navButtonContainer', { static: true }) private navButtonContainer: ElementRef<HTMLDivElement>;

  @Input() public homeSection: HomeSection;
  public contests: Contest[];
  public singleContest: boolean;
  public title: string;
  public swiperOptions: SwiperOptions;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.setSectionContent();
    this.singleContest = this.homeSection?.contests?.length === 1;
    this.contests = this.homeSection?.contests;
    this.setSwiperOptions();
  }

  ngAfterViewInit(): void {
    this.setSwiperHideOptions(); //We run this here to make the renderer work, despite the fact that our components are static: true
  }

  private setSectionContent(): void {
    this.title = this.homeSection.title;
  }

  private setSwiperOptions(): void {
    const nextEl: HTMLElement = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl: HTMLElement = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    this.swiperOptions = {
      modules: [Mousewheel, Navigation],
      navigation: { nextEl, prevEl },
      mousewheel: { forceToAxis: true, releaseOnEdges: false },
      slidesPerView: 'auto',
      grabCursor: true,
      spaceBetween: 16
    };
  }

  private setSwiperHideOptions(): void {
    const nextEl: HTMLElement = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl: HTMLElement = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const buttonContainer: HTMLDivElement = this.navButtonContainer.nativeElement;
    const nextButtonIsDisabled = nextEl.classList.contains('swiper-button-disabled');
    const prevButtonIsDisabled = prevEl.classList.contains('swiper-button-disabled');
    if (nextButtonIsDisabled && prevButtonIsDisabled) {
      this.renderer.addClass(buttonContainer, 'hidden');
    }
  }
}
