import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, HostBinding } from '@angular/core';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
  @ViewChild('input') private input: ElementRef<HTMLInputElement>;

  @Input() public placeholder: string;
  @Input() public debounceTime: number;
  @Input() public searchTerm: string;

  @Output() private keyboardEnterInput = new EventEmitter<string>();
  @Output() private keyboardEscape = new EventEmitter<string>();
  @Output() private clearInput = new EventEmitter<string>();
  @Output() private searchInput = new EventEmitter<string>();
  @Output() private focusInput = new EventEmitter<string>();
  @Output() private blurInput = new EventEmitter<string>();

  @HostBinding('class') public hostClass = 'search-input';
  @HostBinding('class.focused') public isFocused: boolean;

  public search(event: InputEvent): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.searchTerm = searchTerm;
    this.searchInput.emit(searchTerm);
  }

  public onKeydownEnter(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value.trim();
    this.searchTerm = searchTerm;
    this.keyboardEnterInput.emit(searchTerm);
  }

  public focus(): void {
    this.isFocused = true;
    this.focusInput.emit(this.searchTerm);
  }

  public blur(): void {
    this.isFocused = false;
    this.blurInput.emit(this.searchTerm);
  }

  public escape(): void {
    this.input.nativeElement.blur();
    this.keyboardEscape.emit(this.searchTerm);
  }

  public clearValue(): void {
    this.clearInput.emit(this.searchTerm);
    this.searchTerm = '';
  }
}
