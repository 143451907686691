import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { LanguageService } from '@box-core/services/language.service';
import { MarketCard, BarcodeEncodingType } from '@box-types';
import { generateImageSrc } from '@box/utils';

@Component({
  selector: 'market-card',
  templateUrl: './market-card.component.html',
  styleUrls: ['./market-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketCardComponent implements OnChanges {
  @Input() public marketCard: MarketCard;

  public code: string;
  public format: BarcodeEncodingType;
  public imageSrc: string;
  public pointsText: string;
  public numberText: string;

  @HostBinding('class') public hostClass = 'market-card';

  constructor(private languageService: LanguageService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.marketCard = changes.marketCard.currentValue as MarketCard;
    this.code = this.marketCard.cardId;
    this.format = this.marketCard.barcodeFormat;
    this.imageSrc = generateImageSrc(this.marketCard.frontImage);
    const pointsTranslatedText = this.languageService.getTextByKey('points_lowercase');
    this.pointsText = `${this.marketCard.balance} ${pointsTranslatedText}`;
    const cardNumberTranslatedText = this.languageService.getTextByKey('card_number_trimmed');
    this.numberText = `${cardNumberTranslatedText} ${this.marketCard.cardId}`;
  }
}
