<ng-container *ngIf="showRewards">
  <div *ngIf="freeReward" class="checkout-mb-rewards-wrapper">
    <header class="checkout-mb-rewards-header">
      <figure *ngIf="freeRewardImage" class="checkout-mb-rewards-header-image">
        <img [src]="freeRewardImage" alt="MB Rewards" />
      </figure>
      <h3 class="checkout-mb-rewards-header-title ods-typography-body-s-bold">{{ freeRewardTitle | translate }}</h3>
    </header>
    <main class="checkout-mb-rewards-main">
      <div class="checkout-mb-rewards-container">
        <div class="checkout-mb-reward-container">
          <reward-gb class="checkout-mb-reward" [segment]="segment" [reward]="freeReward"></reward-gb>
          <span class="checkout-mb-reward-text ods-typography-display-m">{{ 'gift_' | translate }}</span>
        </div>
      </div>
    </main>
    <footer *ngIf="showClaimedFreeReward" class="checkout-mb-rewards-footer">
      <p class="checkout-mb-rewards-footer-text">
        {{ 'you_will_be_informed_when_you_get_the_gb_on_the_number' | translate }} {{ freeRewardMSISDN }}!
      </p>
    </footer>
  </div>

  <div *ngIf="segmentMBRewards.length > 0" class="checkout-mb-rewards-wrapper">
    <header class="checkout-mb-rewards-header">
      <figure *ngIf="headerImageSrc" class="checkout-mb-rewards-header-image">
        <img [src]="headerImageSrc" alt="MB Rewards" />
      </figure>
      <h3 class="checkout-mb-rewards-header-title ods-typography-body-s-bold">{{ headerTitle | translate }}</h3>
    </header>
    <main class="checkout-mb-rewards-main">
      <p *ngIf="state === 'not-claimed'" class="checkout-mb-rewards-description ods-typography-body-s-regular">
        {{ 'choose_the_package_you_want_and_you_will_have_it_after_completing_your_order' | translate }}
      </p>
      <div *ngIf="showSuggestion" class="checkout-mb-rewards-suggestion">
        <figure class="checkout-mb-rewards-suggestion-image">
          <img [src]="suggestionImageSrc" alt="MB Rewards" />
        </figure>
        <span class="checkout-mb-rewards-suggestion-text">{{ suggestionText | translate }}</span>
      </div>
      <div *ngIf="!claimUnavailable && state === 'not-claimed'" class="checkout-mb-rewards-msisdn">
        <span class="checkout-mb-rewards-msisdn-text ods-typography-body-s-regular"
          >{{ 'you_will_get_the_gb_on' | translate }} {{ selectedAssetInfo.msisdn }}!</span
        >
        <button box-button size="small" theme="insignificant" (click)="onMSISDNChange()">
          {{ 'change_' | translate }}
        </button>
      </div>
      <div class="checkout-mb-rewards-container">
        <ng-container *ngFor="let reward of segmentMBRewards; let last = last">
          <div class="checkout-mb-reward-container">
            <reward-gb
              class="checkout-mb-reward"
              [segment]="segment"
              [reward]="reward"
              [alternativeView]="true"
            ></reward-gb>
            <button
              *ngIf="state === 'not-claimed' && !claimUnavailable"
              box-button
              theme="primary"
              class="checkout-mb-reward-button"
              size="small"
              [disabled]="!reward.orderPriceEligible"
              (click)="onRewardClaim(reward)"
            >
              {{ 'i_want_it' | translate }}
            </button>
          </div>
          <div *ngIf="!reward.orderPriceEligible && state === 'not-claimed'" class="checkout-mb-reward-minimum-price">
            {{ 'unlock_it_by_placing_orders_over' | translate }}
            {{ reward.minimumOrderPrice | currencyPipe }}!
          </div>
          <div *ngIf="!last" class="box-divider"></div>
        </ng-container>
      </div>
    </main>
    <footer *ngIf="state === 'claimed'" class="checkout-mb-rewards-footer">
      <p class="checkout-mb-rewards-footer-text">
        {{ 'you_will_be_informed_when_you_get_the_gb_on_the_number' | translate }} {{ selectedAssetInfo.msisdn }}!
      </p>
    </footer>
  </div>
</ng-container>
