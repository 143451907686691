/*
Keyboard Shortcuts

Right arrow             Increment the slider value by one step (decrements in RTL).
Up arrow                Increment the slider value by one step.
Left arrow              Decrement the slider value by one step (increments in RTL).
Down arrow              Decrement the slider value by one step.
Page up                 Increment the slider value by 10 steps.
Page down               Decrement the slider value by 10 steps.
End                     Set the value to the maximum possible.
Home                    Set the value to the minimum possible.

Accessibility

tabindex                Native selection with Tab
aria-value              Slider Current Value
aria-availableValue     Slider Available Value
aria-claimableValue     Slider Claimable Value
aria-valuemmin          Slider Minimum Value
aria-valuemax           Slider Maximum Value
aria-step               Slider Step Value

*/

import { FocusMonitor } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { clamp } from '@box/utils';
import { Subject, Subscription } from 'rxjs';
import { auditTime } from 'rxjs/operators';

@Component({
  selector: 'box-slider',
  templateUrl: './box-slider.component.html',
  styleUrls: ['./box-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BOXSliderComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('wrapper', { static: true }) private wrapper: ElementRef<HTMLDivElement>;
  @ViewChild('availableBar', { static: true }) private availableBar: ElementRef<HTMLDivElement>;
  @ViewChild('claimableBar', { static: true }) private claimableBar: ElementRef<HTMLDivElement>;
  @ViewChild('activeBar', { static: true }) private activeBar: ElementRef<HTMLDivElement>;
  @ViewChild('thumbContainer', { static: true }) private thumbContainer: ElementRef<HTMLDivElement>;

  @Input() public tabIndex = 0;
  @Input() public value = 0;
  @Input() public availableValue = 0;
  @Input() public claimableValue = 0;
  @Input() public minValue = 0;
  @Input() public maxValue = 100;
  @Input() public step = 1;

  @Output() private inputChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() private valueChange: EventEmitter<number> = new EventEmitter<number>();

  private isSliding = false;
  private sliderDimensions: DOMRect;
  // private lastPointerEvent: Event; // TODO @faropoulos Check if we can use this on blur/focus
  private valueOnSlideStart: number;
  private pointerMoveEvent = new Subject<Event>();
  private pointerMoveEventSubscription: Subscription;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private focusMonitor: FocusMonitor
  ) {}

  @HostBinding('class') public hostClass = 'box-slider';
  @HostBinding('class.box-slider-sliding') public hostSlidingClass: boolean;
  @HostBinding('attr.tabindex') public attrTabIndex: number;
  @HostBinding('attr.aria-value') public ariaValue: number;
  @HostBinding('attr.aria-availableValue') public ariaAvailableValue: number;
  @HostBinding('attr.aria-claimableValue') public ariaClaimableValue: number;
  @HostBinding('attr.aria-valuemmin') public ariaValuemMin: number;
  @HostBinding('attr.aria-valuemax') public ariaValueMax: number;
  @HostBinding('attr.aria-step') public ariaStep: number;

  @HostListener('focus', ['$event']) onFocus(): void {
    this.sliderDimensions = this.getSliderDimensions();
  }

  @HostListener('blur', ['$event']) onBlur(): void {
    this.elementRef.nativeElement.blur();
  }

  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent): void {
    const oldValue = this.value;
    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowRight':
        this.increment(1);
        break;
      case 'ArrowDown':
      case 'ArrowLeft':
        this.increment(-1);
        break;
      case 'PageUp':
        this.increment(10);
        break;
      case 'PageDown':
        this.increment(-10);
        break;
      case 'Home':
        this.value = this.minValue;
        break;
      case 'End':
        this.value = this.maxValue;
        break;
      default:
        return undefined;
    }

    this.onValueUpdate();

    if (oldValue !== this.value) {
      this.emitInputEvent();
      this.emitChangeEvent();
    }

    this.hostSlidingClass = this.isSliding = true;
    event.preventDefault();
  }

  @HostListener('keyup', ['$event']) onKeyUp(): void {
    this.hostSlidingClass = this.isSliding = false;
  }

  @HostListener('mouseenter', ['$event']) onMouseEnter(): void {
    this.sliderDimensions = this.getSliderDimensions();
  }

  @HostListener('selectstart', ['$event']) onSelectStart(event: MouseEvent): void {
    event.preventDefault();
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event: MouseEvent): void {
    this.pointerDown(event);
  }

  @HostListener('touchstart', ['$event']) onTouchStart(event: TouchEvent): void {
    this.pointerDown(event);
  }

  ngOnInit(): void {
    this.initHostBindingValues();
    this.setPointerMoveSubscription();
  }

  ngAfterViewInit(): void {
    this.updateActiveBarStyles();
    this.updateAvailableBarStyles();
    this.updateClaimableBarStyles();
    this.updateThumbContainerStyles();
    this.focusMonitor.monitor(this.elementRef, true).subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      const currentValue = changes.value.currentValue as number;
      this.value = clamp(currentValue, this.minValue, this.maxValue);
      this.onValueUpdate();
    }
  }

  ngOnDestroy(): void {
    if (this.pointerMoveEventSubscription) this.pointerMoveEventSubscription.unsubscribe();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  private emitInputEvent(): void {
    this.inputChange.emit(this.value);
  }

  private emitChangeEvent(): void {
    this.valueChange.emit(this.value);
  }

  private pointerDown = (event: MouseEvent | TouchEvent) => {
    const oldValue = this.value;
    this.hostSlidingClass = this.isSliding = true;
    // this.lastPointerEvent = event;
    event.preventDefault();
    this.sliderDimensions = this.getSliderDimensions();
    this.bindGlobalEvents();
    this.focusHostElement();
    this.updateValueFromPosition(event);
    this.updateHostBindingValues();
    this.valueOnSlideStart = oldValue;
    if (oldValue !== this.value) this.emitInputEvent();
  };

  private pointerMove = (event: MouseEvent) => {
    if (!this.isSliding) return undefined;
    event.preventDefault();
    this.pointerMoveEvent.next(event);
  };

  private pointerUp = (event: MouseEvent) => {
    if (this.isSliding) event.preventDefault();
    this.removeGlobalEvents();
    this.hostSlidingClass = this.isSliding = false;
    if (this.valueOnSlideStart !== this.value) this.emitChangeEvent();
    this.valueOnSlideStart = null;
    // this.lastPointerEvent = null;
  };

  private bindGlobalEvents() {
    document.addEventListener('mousemove', this.pointerMove, { passive: false });
    document.addEventListener('mouseup', this.pointerUp, { passive: false });
    document.addEventListener('touchmove', this.pointerMove, { passive: false });
    document.addEventListener('touchend', this.pointerUp, { passive: false });
    document.addEventListener('touchcancel', this.pointerUp, { passive: false });
  }

  private removeGlobalEvents() {
    document.removeEventListener('mousemove', this.pointerMove);
    document.removeEventListener('mouseup', this.pointerUp);
    document.removeEventListener('touchmove', this.pointerMove);
    document.removeEventListener('touchend', this.pointerUp);
    document.removeEventListener('touchcancel', this.pointerUp);
  }

  private calculatePercentage(value: number) {
    return Math.min(((value || 0) - this.minValue) / (this.maxValue - this.minValue), 1);
  }

  private calculateValue(value: number) {
    return this.minValue + value * (this.maxValue - this.minValue);
  }

  private getSliderDimensions(): DOMRect {
    return this.wrapper ? this.wrapper.nativeElement.getBoundingClientRect() : null;
  }

  private focusHostElement(options?: FocusOptions) {
    this.elementRef.nativeElement.focus(options);
  }

  private increment(steps: number): void {
    this.value = clamp((this.value || 0) + this.step * steps, this.minValue, this.maxValue);
  }

  private updateValueFromPosition(event: MouseEvent | TouchEvent) {
    if (!this.sliderDimensions) return undefined;
    const pointCoords = this.getEventCoordinates(event);
    const positionX = clamp((pointCoords.x - this.sliderDimensions.left) / this.sliderDimensions.width);
    if (positionX === 0) return (this.value = this.minValue);
    if (positionX === 1) return (this.value = this.maxValue);
    const rawValue = this.calculateValue(positionX);
    const newValue = Math.round((rawValue - this.minValue) / this.step) * this.step + this.minValue;
    this.value = clamp(newValue, this.minValue, this.maxValue);
  }

  private isTouchEvent(event: Event): boolean {
    return event.type[0] === 't';
  }

  private getEventCoordinates(event: MouseEvent | TouchEvent): { x: number; y: number } {
    const coords: MouseEvent = (
      this.isTouchEvent(event) ? (event as TouchEvent).touches[0] || (event as TouchEvent).changedTouches[0] : event
    ) as MouseEvent;
    return { x: coords.clientX, y: coords.clientY };
  }

  private updateAvailableBarStyles(): void {
    const backgroundValue = this.calculatePercentage(this.availableValue);
    const transformStyle = `translateX(0px) scale3d(${backgroundValue}, 1, 1)`;
    this.renderer.setStyle(this.availableBar.nativeElement, 'transform', transformStyle);
  }

  private updateClaimableBarStyles(): void {
    const backgroundValue = this.calculatePercentage(this.claimableValue);
    const transformStyle = `translateX(0px) scale3d(${backgroundValue}, 1, 1)`;
    this.renderer.setStyle(this.claimableBar.nativeElement, 'transform', transformStyle);
  }

  private updateActiveBarStyles(): void {
    const backgroundValue = this.calculatePercentage(this.value);
    const transformStyle = `translateX(0px) scale3d(${backgroundValue}, 1, 1)`;
    this.renderer.setStyle(this.activeBar.nativeElement, 'transform', transformStyle);
  }

  private updateThumbContainerStyles(): void {
    const thumbContainerValue = -((1 - this.calculatePercentage(this.value)) * 100);
    const transformStyle = `translateX(${thumbContainerValue}%)`;
    this.renderer.setStyle(this.thumbContainer.nativeElement, 'transform', transformStyle);
  }

  private updateHostBindingValues(): void {
    this.ariaValue = this.value;
  }

  private initHostBindingValues(): void {
    this.attrTabIndex = this.tabIndex;
    this.ariaValue = this.value;
    this.ariaAvailableValue = this.availableValue;
    this.ariaClaimableValue = this.claimableValue;
    this.ariaValuemMin = this.minValue;
    this.ariaValueMax = this.maxValue;
    this.ariaStep = this.step;
  }

  private onValueUpdate(): void {
    this.updateHostBindingValues();
    this.updateThumbContainerStyles();
    this.updateActiveBarStyles();
  }

  private setPointerMoveSubscription(): void {
    this.pointerMoveEventSubscription = this.pointerMoveEvent
      .pipe(auditTime(16))
      .subscribe((event: MouseEvent | TouchEvent) => {
        const newValue = this.value;
        // this.lastPointerEvent = event;
        this.updateValueFromPosition(event);
        this.onValueUpdate();
        if (newValue !== this.value) this.emitInputEvent();
      });
  }
}
