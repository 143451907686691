import { ComponentType } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  HostBinding,
  OnDestroy,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NotificationAnimation } from './notification.animation';
import { NotificationConfig } from './notification.config';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [NotificationAnimation]
})
export class NotificationComponent implements AfterViewInit, OnDestroy {
  @ViewChild('insertion', { read: ViewContainerRef }) private viewContainerRef: ViewContainerRef;

  public componentRef: ComponentRef<unknown>;
  public childComponentType: ComponentType<unknown>;

  constructor(
    private config: NotificationConfig,
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  @HostBinding('attr.id') public id;
  @HostBinding('class') public hostClass = 'notification';
  @HostBinding('@notificationAnimation') public notificationAnimation: boolean;

  ngAfterViewInit(): void {
    this.id = this.config.id;
    this.loadChildComponent(this.childComponentType);
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.componentRef?.destroy();
  }

  private loadChildComponent(componentType: ComponentType<unknown>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    this.viewContainerRef.clear();
    this.componentRef = this.viewContainerRef.createComponent(componentFactory);
  }
}
