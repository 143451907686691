import { captureException } from '@sentry/angular';
import { Injectable, ErrorHandler } from '@angular/core';
import { SentryService } from '@box-core/services';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  private chunkFailedRegExp = /Loading chunk [\d]+ failed/;
  private localStorageSecurityExp =
    /Failed to read the 'localStorage' property from 'Window': Access is denied for this document/;

  constructor(private sentryService: SentryService) {
    this.sentryService.init();
  }

  handleError(error: Error & { originalError?: Error }): void {
    if (!error) return;
    console.error(error);
    /** After a deployment of a new version of the app, if a user is navigation at that time,
    and requests a new Lazy Loaded Module, he will get the ChunkLoadError and the application
    will hang. For that reason we handle this error by forcing a window.location.reload
    in order for the user to download the new index.html file containing the assets with
    the new Hash Value. */
    if (this.chunkFailedRegExp.test(error.message)) return this.handleChunkLoadError();
    if (this.localStorageSecurityExp.test(error.message)) return;
    return this.handleSentryError(error);
  }

  private handleSentryError(error: Error & { originalError?: Error }): void {
    captureException(error.originalError ?? error);
  }

  private handleChunkLoadError(): void {
    // We could enhance this by adding an Info Dialog letting the user reload on demand
    window.location.reload();
  }
}
