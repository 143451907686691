import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  Renderer2
} from '@angular/core';

@Directive({ selector: '[stickyObserver]' })
export class StickyObserverDirective implements AfterViewInit {
  @Input() public stickyClass = 'sticky';
  @Output() private stickyChanges = new EventEmitter<boolean>();

  constructor(private renderer: Renderer2, private elementRef: ElementRef<HTMLElement>) {}

  @HostBinding('class') public hostClass = this.stickyClass;

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.style.position = 'sticky';
    this.renderer.addClass(this.elementRef.nativeElement, this.stickyClass);
    this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'sticky');
    this.initiateObserver();
  }

  private generateOptions(): IntersectionObserverInit {
    const element = this.elementRef.nativeElement;
    const top: string = window.getComputedStyle(element).top; // it can be ''
    // bug fix for Apple products
    const topNumber = parseFloat(top === '' ? '0' : top);
    return {
      threshold: 0,
      root: element.parentElement,
      // The reason we add the 1 pixel here is that we want the Event to fire when the
      // element is at the top of the root. IntersectionObserver will trigger when the
      // element is off the screen (or some part of it), and the margin helps us mock that
      rootMargin: `-${element.offsetHeight + topNumber + 1}px 0px`
    };
  }

  private initiateObserver(): void {
    const options = this.generateOptions();
    const observer = new IntersectionObserver((records, observer) => {
      if (!records.length) return undefined;
      const record = records[0];
      const targetInfo = record.boundingClientRect;
      const rootBoundsInfo = record.rootBounds;
      if (targetInfo.bottom < rootBoundsInfo.top) {
        this.renderer.addClass(this.elementRef.nativeElement, 'is-sticky');
        this.stickyChanges.emit(true);
      }
      if (targetInfo.bottom >= rootBoundsInfo.top && targetInfo.bottom < rootBoundsInfo.bottom) {
        this.renderer.removeClass(this.elementRef.nativeElement, 'is-sticky');
        this.stickyChanges.next(false);
      }
    }, options);

    observer.observe(this.elementRef.nativeElement);
  }
}
