import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  HostBinding,
  ElementRef
} from '@angular/core';
import { ShopBannerSlide, ShopBannerUnfold, ShopBannerText } from '@box-core/animations';
import { PromoColorsOptions, PromoVisualOptions } from '@box-types';

@Component({
  selector: 'promo-tag',
  templateUrl: './promo-tag.component.html',
  styleUrls: ['./promo-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ShopBannerSlide, ShopBannerUnfold, ShopBannerText]
})
export class PromoTagComponent implements OnChanges {
  @Input() public options: PromoVisualOptions;

  public text: string;
  public image: string;

  @HostBinding('class') public hostClasses = `box-promo-tag`;

  constructor(private element: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.options = changes.options.currentValue as PromoVisualOptions;
    this.initializeVars();
  }

  private initializeVars(): void {
    const { text, image, colors } = this.options;
    this.text = text;
    this.image = image;
    this.initializeColors(colors);
  }

  private initializeColors(colors: PromoColorsOptions): void {
    const elementStyle = (this.element.nativeElement as HTMLElement)?.style;
    if (!elementStyle || !colors) return;
    const { background, triangle, foreground, sideTag } = colors;
    elementStyle.setProperty('--background-color', background ?? '');
    elementStyle.setProperty('--triangle-color', triangle ?? '');
    elementStyle.setProperty('--font-color', foreground ?? '');
    elementStyle.setProperty('--side-tag-color', sideTag ?? background ?? '');
  }
}
