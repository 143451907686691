import { Component, Inject, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Shop, GAPromotionConfig, GACloseModalConfig } from '@box-types';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import {
  AnalyticsService,
  CampaignTimerService,
  ExplorePromoCampaignService,
  PurchaseEventService,
  UserService,
  translate
} from '@box-core/services';
import { Subscription } from 'rxjs';
import {
  generateCampaignCollectionCampaignDialogDescriptionText,
  CollectionCampaign,
  isLowOrderProbabilityUserWithCoupon,
  sortCollectionCampaignShops,
  getCollectionCampaignImpressionGAConfig
} from '@box/utils';
import {
  CollectionCampaignDialogResponseData,
  CollectionCampaignsDialogData
} from '@box-core/components/collection-campaigns-dialog/collection-campaign-dialog.types';

@Component({
  selector: 'collection-campaigns-dialog',
  templateUrl: './collection-campaigns-dialog.component.html',
  styleUrls: ['./collection-campaigns-dialog.component.scss']
})
export class CollectionCampaignsDialogComponent extends BoxDialogWrapperComponent implements OnInit, OnDestroy {
  public title: string;
  public collectionCampaigns: CollectionCampaign[] = [];
  public countdown: boolean;
  public selectedCampaign: CollectionCampaign;
  public sortedShops: Shop[];
  public descriptionText: string;
  public readonly INTERSECTION_ROOT_MARGIN = '0px 0px -45px 0px';
  public readonly DEFAULT_INTERSECTION_THRESHOLDS = 1.0;

  private exploreLowProbabilitySubscription: Subscription;

  constructor(
    public override renderer: Renderer2,
    private dialogRef: MatDialogRef<CollectionCampaignsDialogComponent>,
    private campaignTimerService: CampaignTimerService,
    private userService: UserService,
    private explorePromoCampaignService: ExplorePromoCampaignService,
    private analyticsService: AnalyticsService,
    private purchaseEventService: PurchaseEventService,
    @Inject(MAT_DIALOG_DATA) private data: CollectionCampaignsDialogData
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.setDialogData(this.data);
    this.setExploreLowProbabilityPromoSubscription();
  }

  private setDialogData(data: CollectionCampaignsDialogData): void {
    this.collectionCampaigns = data.collectionCampaigns;
    this.title =
      data.collectionCampaigns.length === 1 ? data.collectionCampaigns[0].modalTitle : translate('win_multiple_points');
    this.selectCollectionCampaign(this.collectionCampaigns[0]);
  }

  private selectCollectionCampaign(collectionCampaign: CollectionCampaign): void {
    if (this.selectedCampaign === collectionCampaign) return;
    this.selectedCampaign = collectionCampaign;
    this.countdown = this.selectedCampaign.countdown;
    this.descriptionText = generateCampaignCollectionCampaignDialogDescriptionText(this.selectedCampaign, translate);
    this.sortedShops = this.sortShopsByCampaignAge(collectionCampaign);
  }

  private setExploreLowProbabilityPromoSubscription(): void {
    const exploreCouponCode = this.explorePromoCampaignService.getCoupon()?.code;
    const isNotNewUsersCampaign = this.selectedCampaign.campaignName !== 'new_users';
    const isNotLowOrderProbabilityUser = !isLowOrderProbabilityUserWithCoupon(this.userService.getUser());
    if (!exploreCouponCode || isNotNewUsersCampaign || isNotLowOrderProbabilityUser) return;

    this.exploreLowProbabilitySubscription = this.explorePromoCampaignService.remainingTime$.subscribe((countdown) => {
      if (countdown === 0) return this.closeDialog();
      this.descriptionText = generateCampaignCollectionCampaignDialogDescriptionText(
        this.selectedCampaign,
        translate,
        exploreCouponCode,
        countdown
      );
    });
  }

  ngOnDestroy(): void {
    this.exploreLowProbabilitySubscription?.unsubscribe();
  }

  public closeDialog(data?: CollectionCampaignDialogResponseData): void {
    this.dialogRef.close(data);
    this.triggerAnalyticsCloseModalEvent();
  }

  public onShopCardClick(shop: Shop): void {
    this.triggerAnalyticsSelectPromoEvent(shop);
    this.closeDialog();
  }

  public onTabClick(collectionCampaign: CollectionCampaign): void {
    if (this.collectionCampaigns.length <= 1) return;
    this.selectCollectionCampaign(collectionCampaign);
  }

  public onShowAllShops(): void {
    this.triggerButtonAnalyticsEvents();
    const isExpired =
      this.campaignTimerService.getCampaignTimerState(this.selectedCampaign.campaignName) === 'PROMO_INACTIVE';
    this.closeDialog({ navigateToDiscover: true, collectionCampaign: isExpired ? null : this.selectedCampaign });
  }

  private sortShopsByCampaignAge(collectionCampaign: CollectionCampaign): Shop[] {
    const campaignShops = collectionCampaign.shops;
    return sortCollectionCampaignShops(campaignShops, collectionCampaign);
  }

  public onShopEnteredViewport(shop: Shop): void {
    this.triggerAnalyticsViewPromoEvent(shop);
  }

  private triggerButtonAnalyticsEvents(): void {
    const gaConfig = {
      creative_name: 'button_des_katastimata',
      creative_slot: 'campaign_bottomsheet',
      promotion_id: '',
      promotion_name: this.selectedCampaign.shortTabTitle
    } as GAPromotionConfig;

    this.analyticsService.addGAEcommerceEvent('select_promotion', gaConfig);
  }

  private triggerAnalyticsViewPromoEvent(shop: Shop): void {
    const gaConfig = getCollectionCampaignImpressionGAConfig('', this.selectedCampaign, this.sortedShops, shop);
    this.analyticsService.addGAEcommerceEvent('view_promotion', gaConfig);
  }

  private triggerAnalyticsSelectPromoEvent(shop: Shop): void {
    const gaConfig = getCollectionCampaignImpressionGAConfig('shop', this.selectedCampaign, this.sortedShops, shop);
    this.analyticsService.addGAEcommerceEvent('select_promotion', gaConfig);
    this.updatePurchaseEvent(shop, gaConfig, 'shop');
  }

  private updatePurchaseEvent(shop: Shop, gaConfig: GAPromotionConfig, creativeName: string) {
    if (!gaConfig?.items?.length) return;
    const shopIndex = gaConfig['items'][0].index;
    const purchaseEvent = {
      creativeName: creativeName,
      index: shopIndex,
      itemListName: 'campaign_bottomsheet',
      creativeSlot: 'campaign_bottomsheet',
      promotionName: this.selectedCampaign.shortTabTitle
    };
    this.purchaseEventService.updatePurchaseEvent(shop._id, purchaseEvent);
  }

  private triggerAnalyticsCloseModalEvent(): void {
    const gaConfig = {
      creative_name: this.selectedCampaign.campaignName,
      creative_slot: 'campaign_bottomsheet',
      promotion_id: '',
      promotion_name: this.selectedCampaign.shortTabTitle
    } as GACloseModalConfig;
    this.analyticsService.addGACustomEvent('close_modal', gaConfig);
  }
}
