<div class="food-item-left">
  <div class="food-item-header">
    <promo-badge *ngIf="badgeOptions" [options]="badgeOptions"></promo-badge>
    <div class="ods-typography-body-m-bold">{{ name }}</div>
  </div>
  <div class="food-item-description ods-typography-body-s-regular">{{ description }}</div>
  <div *ngIf="minCartPriceText" class="food-item-min-cart-price-text ods-typography-body-s-regular">
    {{ minCartPriceText }}
  </div>
  <div *ngIf="maxQuantityText" class="food-item-max-quantity-text ods-typography-body-s-regular">
    {{ maxQuantityText }}
  </div>
  <div *ngIf="price" class="food-item-price-container">
    <div class="food-item-price ods-typography-body-m-regular">{{ price | currencyPipe }}</div>
    <div *ngIf="preOfferPrice" class="food-item-starting-price">
      {{ preOfferPrice | currencyPipe }}
    </div>
  </div>
</div>
<div class="food-item-right" [class.food-item-right-with-image]="thumbnailUrl">
  <figure
    *ngIf="thumbnailUrl"
    class="food-item-image"
    [class.food-item-image-non-zero-quantity]="offer.cartQuantity > 0"
    [class.food-item-image-more-than-nine-quantity]="offer.cartQuantity > 9"
  >
    <img loading="lazy" [src]="thumbnailUrl" />
  </figure>
  <box-quantity
    [subtraction]="false"
    [quantity]="offer.cartQuantity"
    (add)="onAdd()"
    [dense]="true"
    (remove)="onRemove()"
  ></box-quantity>
</div>
