import { Injectable, OnDestroy } from '@angular/core';
import { ConfigurationService, OrdersService } from '@box-core/services';
import { Order } from '@box-types';
import { isOrderInProgress } from '@box/utils';
import { orderBy } from 'lodash-es';
import { BehaviorSubject, Subscription, map, timer } from 'rxjs';

@Injectable()
export class OrderStatusPreviewsService implements OnDestroy {
  private readonly ordersSource = new BehaviorSubject<Order[]>([]);
  public readonly orders$ = this.ordersSource.asObservable();

  private intervalTime: number;
  private timeoutSubscription: Subscription;
  private pollingIntervalSubscription: Subscription;

  constructor(private ordersService: OrdersService, private configService: ConfigurationService) {}

  ngOnDestroy(): void {
    this.pollingIntervalSubscription?.unsubscribe();
    this.timeoutSubscription?.unsubscribe();
  }

  public init(orders: Order[]): void {
    if (!orders.length) return;
    /** When we do not have a configuration property for the polling interval
     * or the value is zero, we want to hide this section to avoid issues with
     * the end user experience.
     * We might want to get this check on the home page, if we decide to have
     * a Home Section state management and rely on Subscription and trackBy
     * to Remove them from the View.*/
    this.intervalTime = this.getOrderPollingIntervalNumber();
    if (!this.intervalTime) return;
    this.ordersSource.next(orders);
    this.startOrderPolling();
  }

  private update(orders: Order[]): void {
    this.ordersSource.next(orders);
    const isPolling = this.isPollingActive();
    if (!orders.length && isPolling) return this.stopOrderPolling();
    if (orders.length && !isPolling) this.startOrderPolling();
  }

  private startOrderPolling(): void {
    this.pollingIntervalSubscription = timer(0, this.intervalTime).subscribe(() => {
      const friendlyIds = this.ordersSource.getValue().map((order) => order.friendlyId);
      this.ordersService
        .fetchOrders(friendlyIds)
        .pipe(
          map((orders) => {
            this.ordersService.updateOrderHistory(orders);
            const eligibleOrders = orders.filter((order) => isOrderInProgress(order));
            return orderBy(eligibleOrders, 'createdAt', 'desc');
          })
        )
        .subscribe((orders) => this.update(orders));
    });
  }

  private stopOrderPolling(): void {
    if (!this.isPollingActive()) return;
    this.pollingIntervalSubscription.unsubscribe();
  }

  private isPollingActive(): boolean {
    if (!this.pollingIntervalSubscription) return false;
    return !this.pollingIntervalSubscription.closed;
  }

  private getOrderPollingIntervalNumber(): number {
    const config = this.configService.getConfiguration();
    return config?.mainViewPollingSeconds * 1000 || 0;
  }
}
