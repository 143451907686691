import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { CheckoutSuggestionBanner, PromoVisualOptions } from '@box-types';

@Component({
  selector: 'checkout-suggestion-banner-card',
  templateUrl: './checkout-suggestion-banner-card.component.html',
  styleUrls: ['./checkout-suggestion-banner-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: []
})
export class CheckoutSuggestionBannerCardComponent implements OnChanges {
  @Input() public checkoutSuggestionBanner: CheckoutSuggestionBanner;
  @Output() private bannerSelect = new EventEmitter<CheckoutSuggestionBanner>();

  public tagOptions: PromoVisualOptions;
  public imageSrc: string;
  public campaignName: string;

  @HostBinding('class') public hostClass = 'checkout-suggestion-banner-card';

  ngOnChanges(changes: SimpleChanges): void {
    this.checkoutSuggestionBanner = changes.checkoutSuggestionBanner.currentValue as CheckoutSuggestionBanner;
    this.tagOptions = { text: this.generateTagOptionsText() };
    this.imageSrc = this.checkoutSuggestionBanner.cardImage;
    this.campaignName = this.checkoutSuggestionBanner.campaignName;
  }

  public onBannerSelect(event: Event): void {
    event.stopPropagation();
    this.bannerSelect.emit(this.checkoutSuggestionBanner);
  }

  private generateTagOptionsText(): string {
    const { multiplier, points } = this.checkoutSuggestionBanner;
    if (multiplier) return `x${multiplier}`;
    return `+${points}`;
  }
}
